@import "../../styles/variables.scss";

.valueContainer {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 16px;
  width: 100%;
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
    @media screen and (max-width: 1440px) {
      position: relative;
      top: 5px;
    }
  }
  .valueCenter {
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: right;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}

.rangeTrackContainer {
  height: 36px;
  display: flex;
  width: 100%;
  @media screen and (max-width: 1440px) {
    height: 30px;
  }
}

.rangeTrack {
  height: 4px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
}

.rangeThumb {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background-color: $white;
  box-shadow: 0px 2px 6px rgba($color: $grey, $alpha: 0.6);
}

.rangeThumb:focus {
  outline: none;
}

.textValue {
  position: absolute;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  max-width: 150px;
  font-size: 18px;
  color: $primaryColor;
  transform: translate(0, 30px);
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    transform: translate(0, 22px);
  }
}

.noSpace {
  max-width: unset;
  white-space: nowrap;
}

.advanceRangeTrack {
  height: 4px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
  background: rgb(230, 230, 230) !important;
}

.advancerangeThumb {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  background-color: $sliderColor;
  outline: none;
  &:active {
    box-shadow: 0 0 4px $grey;
  }
  @media screen and (max-width: 1024px) {
    height: 18px;
    width: 18px;
  }
}

/* Input slider component */

.inputSlider {
  background-color: $sliderColor !important;
  height: 20px !important;
  width: 20px !important;
  box-shadow: 0 2px 6px rgba($color: $dark, $alpha: 0.3);
  margin-top: -7.4px !important;
}

.inputTrack {
  height: 8px !important;
}

.inputLabel {
  display: none;
}

:global(.rc-slider-handle):active {
  box-shadow: 0 0 4px $grey !important;
}
