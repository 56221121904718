@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1000px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $white;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 198px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      max-height: calc(100vh - 150px);
    }
    @media screen and (max-width: 1366px) {
      max-height: calc(100vh - 132px);
    }
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 15px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.spotifyProfile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  label {
    letter-spacing: 0.4px;
    color: $primaryColor;
    text-transform: uppercase;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
    }
  }
  a {
    color: $black;
    letter-spacing: 0.76px;
    text-decoration: underline;
    display: block;
    word-break: break-all;
    &:hover {
      color: $black;
    }
    @media screen and (max-width: 1440px) {
      font-size: 13px;
      letter-spacing: 0.56px;
    }
  }
  .imgContainer {
    width: 122px;
    height: 122px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
    flex-shrink: 0;
    img {
      width: 100%;
    }
    @media screen and (max-width: 1440px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 1366px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 1024px) {
      width: 90px;
      height: 90px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.detailsContainer {
  width: 100%;
  padding: 0 0 110px 0;
  form {
    & > div {
      margin-bottom: 0 !important;
    }
    .borderBottom {
      border-bottom: 1px solid $light;
    }
  }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  &::after {
    content: " ";
    width: 80px;
    border-bottom: 1px solid $darkGreyColor;
    position: absolute;
    transform: translateY(50px);
  }
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 36px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    padding: 30px 0 0;
  }
  @media screen and (max-width: 1440px) {
    font-size: 27px;
    letter-spacing: 1.08px;
  }
}

.detailsBody {
  display: flex;
  justify-content: space-between;
  & > .formContainer {
    flex: 1 1 auto;
    & > div {
      padding: 15px 0 0;
      position: relative;
      & > div {
        margin-bottom: 0 !important;
      }
    }
    .socialUrlForm {
      text-align: left;
      max-width: 530px;
      .socialTitle {
        position: relative;
        font-size: 24px;
        letter-spacing: 0.96px;
        margin-bottom: 30px;
        &::after {
          content: " ";
          width: 80px;
          left: 0;
          border-bottom: 1px solid $darkGreyColor;
          position: absolute;
          transform: translateY(60px);
          @media screen and (max-width: 1440px) {
            transform: translateY(40px);
          }
        }
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          margin-bottom: 25px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
      @media screen and (max-width: 767px) {
        max-width: unset;
      }
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  & > .profileEdit {
    flex: 0.2 1 auto;
  }
  svg {
    color: $grey;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    & > .formContainer {
      margin-right: 0;
    }
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1366px) {
    font-size: 16px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  position: relative;
  text-align: center;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  a {
    padding: 18px 30px;
    min-width: 200px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: bold;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    width: 100%;
    margin: 0 20px;
    text-transform: uppercase;
    &.primary {
      background-color: $primaryColor;
      color: $white;
    }
    &.secondary {
      background-color: transparent;
    }
  }
  .close {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 26px;
    cursor: pointer;
    line-height: 26px;
  }
  h3 {
    text-align: center;
    font-weight: bold;
    color: $primaryColor;
    letter-spacing: -0.86px;
  }
  p {
    font-size: 18px;
    margin: 20px;
    max-width: 300px;
  }
  .hr {
    width: 100%;
    border-bottom: 2px solid $light;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.formMainButtons {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 15px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      margin: 20px 5px;
      color: $ctaColor;
      &.secondary {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 10px 0;
  }
}
