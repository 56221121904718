@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1000px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $white;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}
.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 198px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      max-height: calc(100vh - 150px);
    }
    @media screen and (max-width: 1366px) {
      max-height: calc(100vh - 132px);
    }
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 10px 10px 10px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 4px;
  }
}
.description {
  text-align: center;
  letter-spacing: -1.1px;
  padding: 0 0 21px;
  margin-top: 20px;
  p:first-child {
    font-size: 20px;
  }
}
.hLine {
  margin-top: -2px;
  border-bottom: 1px solid #e6e6e6;
}
.sliderContainer {
  margin-bottom: 150px;
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid $darkGreyColor;
  & > div {
    max-width: 750px;
  }
  .sliderData {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
}
.rangeThumb {
  background-color: $primaryColor;
}
.sliderBox {
  margin: 0 0 24px;
}
.description p {
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: -1px;
  line-height: 18px;
  color: $grey;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
}
.contentname {
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.34px;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
  & > * {
    white-space: nowrap;
    @media screen and (max-width: 1440px) {
      white-space: unset;
      text-align: left;
    }
    @media screen and (max-width: 1366px) {
      white-space: unset;
      text-align: left;
    }
    @media screen and (max-width: 767px) {
      white-space: unset;
      text-align: left;
    }
  }
  p {
    margin: 0;
    margin-right: 15px;
  }
  span {
    color: $primaryColor;
    font-family: $circularBold;
  }
  i {
    margin-left: auto;
  }
}
.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 36px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    padding: 30px 0 0;
  }
}

.youraccountCount {
  font-family: $circularStd;
  font-size: 34px;
  text-align: center;
  display: inline-block;
  margin-top: -4px;
  margin-left: -3px;
  letter-spacing: 0px;
  font-weight: lighter;
  sup {
    top: -0.3em;
    font-size: 23px;
  }
}

.totalStreams {
  position: absolute;
  left: 0;
  top: 0;
  padding: 19px 14px;
  background: #f1f1f1;
  text-align: center;
  @media screen and (max-width: 768px) {
    position: relative;
  }
  p {
    letter-spacing: -0.1px;
    line-height: 17px;
    font-weight: bold;
    margin: 0 0 11px;
  }
  button {
    display: block;
    padding: 7px 60px;
    width: 100%;
    line-height: 16px;
    background: $black;
    color: $white;
    border: none;
    letter-spacing: -0.9px;
    @media screen and (max-width: 768px) {
      margin: auto;
      max-width: 300px;
    }
  }
}
.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
}

.statsContainer {
  margin-top: 28px;
  .header {
    background-color: $light;
    color: $medium;
    font-weight: 500;
    letter-spacing: 0px;
    margin: 0;
    padding: 10px 20px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.statsBox {
  padding: 9px;
  position: relative;
  border-top: 3px solid;
  width: 40.7%;
  margin-right: 7.7%;
  margin-top: 6px;
  float: left;
  margin-left: 1.5%;
  img {
    height: 55px;
    width: 55px;
    margin-right: 15px;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin: 0;
    padding: 5px 0;
    width: calc(100% - 90px);
    @media screen and (max-width: 767px) {
      letter-spacing: -0.4px;
      padding: 5px 0 0;
    }
  }
  p {
    margin: 0;
    line-height: 16px;
    font-size: 15px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
    border-top: 1px solid;
    padding: 9px 9px 17px;
  }
}

.statsBox:nth-child(2n + 3) {
  clear: both;
}
.nextContact {
  font-size: 19px;
  padding: 20px 0px 3px 0px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.fixedBtn {
  position: fixed;
  bottom: 70px;
  left: 0px;
  z-index: 4;
  width: 100%;
  background: #e7e7e7;
  padding: 0 0 30px 0;
  @media screen and (max-width: 767px) {
    position: static;
    padding: 30px 0;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .btnyouradvance:first-child {
    border: 1px solid $primaryColor;
    color: $primaryColor;
    background-color: $white;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .btnyouradvance {
    min-width: 220px;
    padding: 15px 30px;
    color: $white;
    background-color: $primaryColor;
    border: none;
    font-weight: 600;
    font-size: 22px;
    border-radius: 2px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.statsTitle {
  letter-spacing: -2px;
  margin-bottom: 2px;
}
.statsCount {
  font-size: 34px;
  letter-spacing: initial;
  margin-bottom: 0;
  padding: 0 0 42px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    padding: 0 0 5px;
  }
}
.statsSource {
  position: absolute;
  right: 7px;
  top: 12px;
  padding: 4px 8px;
  border: 1px solid #f3f3f3;
  color: #000;
  border-radius: 3px;
  letter-spacing: -0.7px;
  @media screen and (max-width: 767px) {
    right: 9px;
    top: 10px;
  }
}
.statsSource img {
  width: 15px;
  height: 15px;
  margin: 0 6px 0 0;
}
.mainFooter {
  position: relative;
  display: block;
  clear: both;
  margin: 0;
  width: 95%;
  align-self: center;
  padding-bottom: 240px;
  @media screen and (max-width: 767px) {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }

  .topFooter {
    background-color: #f1f1f1;
    padding: 30px;
  }
  .footerContent {
    padding-top: 22px;
    letter-spacing: 1px;
    line-height: 18px;
    margin: auto;
  }

  .income {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      font-size: 16px;
      margin: 10px 3px;
      color: black;
      text-align: center;
    }
  }
  .complete {
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .FLine {
      text-transform: uppercase;
      color: $btnDeactivatedText;
      font-weight: 600;
      letter-spacing: 2px;
      @media screen and (max-width: 767px) {
        margin: auto;
        width: 100%;
        min-width: fit-content;
      }
    }
  }
  .term {
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .FLine {
      letter-spacing: 2px;
      color: $btnDeactivatedText;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .termEnd {
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
    .FLine {
      letter-spacing: 2px;
      color: $btnDeactivatedText;
      text-transform: uppercase;
      margin-left: auto;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        margin: auto;
        width: 100%;
        min-width: fit-content;
      }
    }
  }
  h1 {
    font-size: 31px;
    display: inline-block;
    font-weight: 900;
    width: 100%;
    text-align: center;
    letter-spacing: -0.8px;
  }

  .Box {
    width: 50%;
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
  .footerContentTerm {
    display: inline-block;
    max-width: 30%;
  }
  .footerContentEnd {
    display: inline-block;
    max-width: 30%;
    text-align: right;
  }
  h4 {
    padding: 2px 14px 9px;
  }
  .footerBoxContent {
    padding: 0 0 0 45px;
    line-height: 22px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .footerBox ul {
    list-style: none;
    padding: 0;
  }
  .footerBox > ul {
    padding: 0 10px 0 0px;
  }
  .footerBox > ul > li > label {
    background: #000;
  }
  .footerBox li {
    padding: 8px 0px;

    label {
      padding: 7px 21px 7px 15px;
      margin: 0;
      width: 100%;
      span {
        letter-spacing: 1.2px;
      }
    }
    .leftItem {
      float: left;
    }
    .rightItem {
      float: right;
    }
  }
  .footerBox > ul > li {
    padding: 8px 0 21px;
  }
  .footerBox > ul > li > ul {
    padding-top: 2px;
  }
  .footerBox > ul > li > ul > li {
    padding: 0 20px;
  }
  .footerBox > ul > li > ul > li > label {
    padding: 0;
  }
  .footerBox > ul > li > ul > li > label span {
    letter-spacing: 0;
  }
  .footerBox > ul > li > ul > li > ul {
    padding: 0px 0 11px 10px;
  }
  .footerBox > ul > li > ul > li > ul > li {
    padding: 0;
    line-height: 22px;
  }
  .footerBox > ul > li > ul > li > ul > li > label {
    padding: 0;
  }
  .footerBox > ul > li > label > span.leftItem {
    letter-spacing: 1.4px;
  }
  .footerBox > ul > li > label > span.rightItem {
    letter-spacing: 0.5px;
  }
}

.modalTopBorder {
  border-top: 5px solid $black;
}

.dataReviewContainer {
  .title {
    align-self: center;
    margin: 10px auto 0 auto;
  }
}
.welcomeModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $white;
  margin: 0px auto;
  align-items: center;
  .contactSubText {
    display: block;
    color: $helpTextColor;
    font-size: 80%;
    text-align: center;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
  .dataReviewSubHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      width: 100%;
      text-align: left;
      padding: 10px 0 20px;
      font-size: 18px;
      letter-spacing: 0.96px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.76px;
        line-height: 1.4;
        padding: 10px 0 10px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 14px;
        letter-spacing: 0.76px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .buttonContainer {
      display: flex;
      button {
        margin: 10px;
        font-weight: bold;
        flex-shrink: 0;
        flex-grow: 1;
        max-width: 280px;
        border-radius: 2px;
        border: 1px solid $ctaColor;
        height: 50px;
        text-align: center;
        background-color: transparent;
        color: $ctaColor;
        &.secondary {
          border: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &.primary {
          background-color: transparent;
        }
        &.demoBtn {
          background-color: $primaryColor;
          font-family: $circularBold;
          font-size: 18px;
          max-width: fit-content;
          color: $white;
          border: none;
          border-radius: 4px;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 40px;
            margin-right: -18px;
            stroke: $white;
            @media screen and (max-width: 767px) {
              width: 35px;
              margin-right: -10px;
            }
          }
          @media screen and (max-width: 1024px) {
            font-size: 17px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 16px;
            padding: 10px 15px;
          }
        }
        @media screen and (max-width: 1440px) {
          height: 40px;
          max-width: 200px;
        }
      }
    }
  }
  .dataTuneButton {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 10px 0;
    background: $white;
    border-top: 1px solid $light;
    @media screen and (max-width: 1440px) {
      padding: 0;
    }
    .buttonContainer {
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1440px) {
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      border-top: 1px solid $darkGreyColor;
    }
  }
  .dataReviewSecondHeading {
    position: absolute;
    z-index: 4;
    bottom: 110px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    table {
      text-align: center !important;
      border-top: 1px solid $light;
      padding: 30px 43px;
      overflow: hidden;
      width: 100%;
      font-size: 18px;
      tr {
        > td:first-child {
          border-right: 1px solid $darkGreyColor;
          display: flex;
          flex-direction: column;
          align-items: center;
          @media screen and (max-width: 767px) {
            flex-direction: row;
            border-right: none;
            border-bottom: 1px solid $darkGreyColor;
            padding-bottom: 5px;
          }
        }
        > td.notLookRight {
          max-width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767px) {
            max-width: unset;
            justify-content: flex-start;
            padding-bottom: 12px;
            margin-top: -5px;
          }
        }
        height: 85px;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 1440px) {
          height: 80px;
        }
        @media screen and (max-width: 1366px) {
          height: 80px;
        }
        @media screen and (max-width: 1024px) {
          height: 96px;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          height: unset;
        }
      }
      @media screen and (max-width: 767px) {
        border-top: 1px solid $darkGreyColor;
        background-color: $veryLight;
      }
    }
    @media screen and (max-width: 1440px) {
      bottom: 62px;
    }
    @media screen and (max-width: 1366px) {
      bottom: 62px;
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 62px;
    }
  }
  .modalHead {
    padding: 30px 43px;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .artistImage {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 30px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      img {
        width: 100%;
        height: auto;
      }
      svg {
        height: 100%;
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-height: unset;
      min-height: unset;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $dark, $alpha: 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $dark, $alpha: 0.4);
    }
  }
  .lastModalRow td {
    margin: 14px 10px;
    flex: 1;
    &.dataDisplay {
      p p {
        color: $primaryColor !important;
        font-family: $circularBold;
      }
    }
    > p:first-child,
    label {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.48px;
      .contactSubText {
        display: block;
        position: absolute;
        font-size: 80%;
        font-weight: normal;
        text-decoration: underline;
        cursor: pointer;
      }
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    > p:nth-child(2),
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      margin: auto;
      max-width: 400px;
      margin-top: 2px;
      color: $primaryColor;
      font-family: $circularBold;
      & > div {
        font-size: 20px;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          margin-top: -15px;
        }
      }
      p {
        font-size: 16px;
        color: $black;
        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 1440px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 18px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        max-width: unset;
        margin: 0 10px;
      }
    }
    @media screen and (max-width: 1440px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 1024px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 767px) {
      margin: 8px 15px;
      &.dataDisplay {
        display: flex;
        align-items: center;
        p {
          text-align: left;
          p {
            display: block;
          }
        }
      }
    }
  }
  .soundExchange {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
      font-family: $circularStd;
    }
  }
  td .soundExchange {
    p {
      font-size: 18px;
    }
  }
  .soundExchangeInputContainer {
    display: flex;
    justify-content: center;
    p {
      font-weight: normal;
      margin-right: 5px;
    }
  }

  .dataReviewSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 185px;
  }
  .tuneDataSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 175px;
    @media screen and (max-width: 767px) {
      margin-bottom: 250px;
    }
  }
  h4,
  h3 {
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.86px;
    color: $primaryColor;
    line-height: 26px;
  }
  h4 {
    font-size: 26px;
  }
  h3 {
    font-size: 31px;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }
  p {
    margin: 0;
  }
}

.modalBody table {
  padding: 30px 43px;
  overflow: hidden;
  width: 100%;
  tbody {
    display: block;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $dark, $alpha: 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $dark, $alpha: 0.4);
    }
  }
  thead {
    display: table;
    table-layout: fixed;
    width: calc(100% - 8px);
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  p {
    font-size: 18px;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
  tr {
    border-bottom: 1px solid $light;
    & > th:first-child,
    & > td:first-child {
      padding: 10px 0 10px 0;
      text-align: center;
      @media screen and (max-width: 1440px) {
        padding: 7px 0;
      }
      @media screen and (max-width: 767px) {
        padding: 20px 0 10px 0;
      }
    }
    & > th:last-child,
    & > td:last-child {
      padding-right: 14px;
      text-align: center;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: unset;
        position: relative;
      }
      .mobShareIncome {
        display: none;
        @media screen and (max-width: 767px) {
          display: inline;
        }
      }
      .mobSliderIncome {
        text-align: left;
        font-size: 14px;
        > span {
          display: none;
          @media screen and (max-width: 767px) {
            display: block;
            text-transform: uppercase;
            font-family: $circularBold;
            color: $grey;
            letter-spacing: 0.46px;
            margin-bottom: -18px;
          }
        }
        @media screen and (max-width: 767px) {
          div span {
            color: $primaryColor;
            font-family: $circularBold;
          }
        }
      }
      @media screen and (max-width: 1024px) {
        padding-right: 20px;
        p {
          width: auto;
          position: static;
          right: 0px;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 15px 10px;
        p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          font-family: $circularBold;
          color: $grey;
          margin-top: -20px;
          .mobShare {
            color: $primaryColor;
            margin-left: 15px;
          }
        }
      }
    }
    &:last-child > * {
      text-align: center;
      font-weight: 600;
      vertical-align: middle;
      &:first-child {
        text-align: left;
      }
    }
    &.lastModalRow {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  th,
  .th {
    color: $grey;
    text-transform: uppercase;
    letter-spacing: 0.48px;
    font-family: $circularBold;
    p {
      font-size: 12px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 1024px) {
      letter-spacing: 0.5px;

      p {
        white-space: unset;
      }
    }
    @media screen and (max-width: 340px) {
      &:nth-child(2) p {
        display: none;
      }
    }
  }
  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }
  .trackProfile {
    display: flex;
    align-items: center;
    width: 100%;
    img {
      height: 60px;
      width: 60px;
      margin-right: 20px;
      flex-shrink: 0;
      border-radius: 50%;
    }
    svg {
      width: 60px;
      height: auto;
      margin-right: 10px;
      flex-shrink: 0;
    }
    p {
      font-weight: 600;
      text-align: left;
    }
    .mobLastMonth {
      text-transform: uppercase;
      color: $grey;
      display: none;
      letter-spacing: 0.76px;
      padding-top: 8px;
      span {
        color: $black;
        margin-left: 10px;
      }
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    @media screen and (max-width: 1440px) {
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 1366px) {
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 1024px) {
      img {
        height: 40px;
        width: 40px;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      img {
        height: 50px;
        width: 50px;
      }
      svg {
        width: 50px;
      }
    }
  }
  .chartData {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    p {
      margin-right: 15px;
    }
    @media screen and (max-width: 1440px) {
      & > div {
        top: 5px;
      }
    }
    @media screen and (max-width: 1024px) {
      p {
        margin: 0;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  div.th {
    margin: 20px 0;
    p {
      justify-content: center;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .partitionTitle {
    td {
      padding: 12px 10px;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }

    background-color: $light;
    p {
      color: $dark;
      text-align: left;
      flex: 1;
      margin-left: 10px;
    }
  }
}

.tuneDataModal {
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  .modalBtnContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    button.primary {
      margin: 0;
      color: $white;
    }
    button {
      color: $dark;
    }
  }
}

.tuneDataModal .lastTable {
  @media screen and (max-width: 1024px) {
    tr:first-child {
      display: none;
    }
    tr:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      td {
        max-width: 500px;
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid $light;
        > * {
          text-align: center;
          align-items: center;
        }
      }
    }
  }
}

.tuneDataModal table tr {
  &.lastRow td {
    font-weight: 600;
    vertical-align: top;
    padding-bottom: 20px;
  }
  &:last-child > * {
    text-align: center;
    font-weight: normal;
    vertical-align: middle;
    padding-bottom: 5px;
    &:first-child {
      text-align: center;
    }
  }
  .linkText {
    letter-spacing: -0.34px;
    text-decoration: underline;
    color: $red;
    cursor: pointer;
    font-weight: normal;
  }
  @media screen and (max-width: 1024px) {
    td {
      padding: 20px 10px;
    }
  }
}

.fundingSave {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 44px;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 10px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;
  &.atBottom {
    bottom: 0;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      margin: 20px 5px;
      color: $ctaColor;
      &.secondary {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
      }
      &.longBtn {
        width: unset;
        max-width: unset;
      }
      &.demoBtn {
        background-color: $primaryColor;
        font-family: $circularBold;
        font-size: 18px;
        max-width: fit-content;
        width: unset;
        color: $white;
        border: none;
        border-radius: 4px;
        padding: 12px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 40px;
          margin-right: -18px;
          stroke: $white;
          @media screen and (max-width: 767px) {
            width: 35px;
            margin-right: -10px;
          }
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 14px;
          padding: 10px 15px;
        }
      }
      @media screen and (max-width: 1440px) {
        margin: 15px 5px;
      }
      @media screen and (max-width: 767px) {
        margin: 10px 5px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 0;
    box-shadow: unset;
  }
}

.readBtn {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 10px 0;
  color: $alertFontColor;
  background: $alertBackgroundColor;
  margin: 0;
  @media screen and (max-width: 767px) {
    position: fixed;
    margin-bottom: 0;
    box-shadow: unset;
  }
}

.footerText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $veryLight;
  color: $primaryColor;
  z-index: 4;
  height: 350px;
  overflow: hidden;
  transition: height 0.3s ease;
  border-top: 1px solid $darkGreyColor;
  @media screen and (max-width: 1024px) {
    height: 450px;
  }
  .close {
    align-self: flex-end;
    margin-right: 30px;
    font-size: 26px;
    cursor: pointer;
    padding: 10px;
    line-height: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 20px;
    }
  }
  & > p {
    text-align: center;
    letter-spacing: -0.79px;
    line-height: 21px;
    font-weight: 400;
    font-size: 18px;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 14px;
    }
  }
  .whitelabelFinePrint {
    height: 200px;
    overflow: auto;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    box-shadow: unset;
  }
}

.footerHide {
  height: 0;
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  font-weight: normal;
  text-align: left;
  background-color: $tooltipBackgroundColor !important;
  color: $tooltipFontColor !important;
  box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
  border-radius: 5px !important;
  &::after {
    border: transparent !important;
  }
  &:global(.show) {
    opacity: 1 !important;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.smallModalContainer {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.smallModalBody {
  background-color: $white;
  border: 3px solid $primaryColor;
  padding: 50px;
  max-width: 700px;
  text-align: left;
  width: 90%;
  margin: auto;
  margin-bottom: 70px;
  p {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .link {
    cursor: pointer;
    color: $primaryColor;
    text-decoration: underline;
  }
  a {
    text-decoration: underline;
  }
  .btn {
    background-color: $primaryColor;
    color: $white;
    padding: 10px 20px;
    border: none;
    margin: auto;
    display: block;
  }
  @media screen and (max-width: 767px) {
    margin: 20px auto;
    padding: 20px;
    text-align: left;
    width: auto;
  }
}

// contact beatbread to get funded page style start
.contactMainContainer {
  background-color: $white;
  display: flex;
  width: 100%;
  margin-top: 0px;
  position: relative;
}
.contactContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 16%;
  @media (min-width: 320px) {
    width: 90%;
    padding-top: 20px;
  }
  @media (min-width: 639px) {
    max-width: 350px;
  }
  @media (min-width: 768px) {
    padding-top: 56px;
    max-width: 490px;
  }
  @media (min-width: 992px) {
    max-width: 570px;
    padding-top: 56px;
  }
  @media (min-width: 1024px) {
    max-width: 685px;
    padding-top: 56px;
  }
  @media (min-width: 1200px) {
    max-width: 980px;
    padding-top: 56px;
  }
}
.contactHeader {
  font-weight: 600;
  line-height: 48px;
  font-size: 48px;
  font-family: $circular;
  letter-spacing: -2.5px;
  padding: 20px 0 0 0 !important;
  color: $primaryColor;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contactText {
  line-height: 31.5px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.875px;
  color: $black;
  margin-top: 12px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}
.buttonLine {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}
.noteItem {
  @media (max-width: 1198px) {
    margin-top: 40px;
  }
  line-height: 31.5px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.875px;
  color: $black;
  padding: 0 8px;
}
.scheduleBtn {
  height: 61px;
  border-radius: 2px;
  background-color: $primaryColor;
  font-weight: 600;
  font-size: 18px;
  color: $white;
  line-height: 60px;
  letter-spacing: -0.34px;
  border: none;
  padding-left: 6px;
  padding-right: 7px;
  width: 265px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
}
.goBackBtn {
  height: 61px;
  width: 265px;
  border-radius: 2px;
  background-color: #7b7b7b;
  font-weight: 600;
  font-size: 18px;
  color: $white;
  line-height: 60px;
  letter-spacing: -0.34px;
  border: none;
  padding-left: 6px;
  padding-right: 7px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
.stepRow {
  margin-top: 64px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 511px) {
    flex-direction: column;
  }
  svg {
    width: 65px;
    height: 65px;
    display: block;
    margin: 0px auto;
    position: relative;
    top: -10px;
    path {
      fill: $primaryColor;
    }
  }

  svg g {
    fill: $primaryColor;
  }
}

.stepsSection {
  margin-top: 32px;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    padding: 0 88px;
  }
}

.scheduleBtn:hover {
  color: $white;
}
.main_success {
  display: table;
  width: 100%;
}
.success {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  background-color: $white;
  visibility: visible !important;
  height: 100vh;
  text-align: center;
  h1 {
    font-size: 65px;
    font-family: $circular;
    font-weight: bold;
    color: $primaryColor;
  }
  p {
    clear: both;
    font-weight: bold;
  }
}
.goBackLink {
  color: $primaryColor;
  font-weight: 600;
  text-decoration: underline;
}
.goBackLink:hover {
  text-decoration: underline;
}

.reactRangeLastMonth {
  width: 100%;
  min-width: 120px;
  padding: 0 15px;
  height: 28px;
  div {
    font-size: 16px;
    & > div > div {
      transform: translate(0, 22px);
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.valueContainer {
  display: none;
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $alertFontColor;
    color: $alertBackgroundColor;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.artistCollapse {
  position: fixed;
  right: 0;
  top: 130px;
  padding: 20px 20px 20px 30px;
  background-color: $primaryColor;
  text-align: left;
  color: $white;
  border-radius: 30px 0 0 30px;
  transition: padding 0.3s;
  margin-left: 20px;
  z-index: 6;
  box-shadow: 0 2px 12px -7px $black;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    background-color: $white;
    border: 2px solid $primaryColor;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(-40%, -40%);
    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: translateY(-2px) rotate(45deg);
      transition: transform 0.3s;
      @media screen and (max-width: 767px) {
        height: 10px;
        width: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      height: 30px;
      width: 30px;
      transform: translate(-30%, -30%);
    }
  }
  .collapseTitle {
    opacity: 0.6;
    margin-bottom: 0;
    font-family: $circularBold;
    transition: font-size 0.3s, margin 0.3s;
    &.activeGuide {
      opacity: 1;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  &.open {
    padding-right: 50px;
    .arrow::after {
      transform: translateY(2px) rotate(225deg);
    }
    .collapseTitle {
      font-size: 24px;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 1366px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 767px) {
      padding-right: 25px;
    }
  }
  .collapseContent {
    margin-bottom: 20px;
    &.inactiveGuide {
      opacity: 0.6;
    }
    :global {
      .getFundedContainer {
        min-width: 440px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;
        & > div {
          background-color: transparent;
          & > div {
            margin: 0;
            border-radius: 20px;
            min-height: unset;
            @media screen and (max-width: 767px) {
              min-width: unset;
              width: 100%;
            }
            & > div {
              padding: 0;
            }
          }
        }
        button[type="submit"] {
          color: $white;
          border: none;
        }
        iframe {
          border: none;
          width: 100%;
          height: 549px;
          border-radius: 20px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          min-height: unset;
          min-width: unset;
          overflow-x: hidden;
          padding: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    top: 80px;
    padding: 15px 10px 15px 25px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 10px 10px 20px;
    max-width: 96%;
  }
}
