@import "../../styles/variables.scss";

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
  @media screen and (max-height: 700px) {
    align-items: unset;
  }
}

.loading {
  background-color: transparent;
}

.contactModal {
  display: flex;
  flex-direction: column;
  max-width: 830px;
  width: 90%;
  background-color: $white;
  margin: 20px auto 78px;
  align-items: center;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0 40px;
  .title {
    border-bottom: 2px solid $light;
    margin-bottom: 10px;
    width: 100%;
    font-family: $circular;
    letter-spacing: -2.5px;
    h3 {
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.86px;
      color: $primaryColor;
      line-height: 26px;
      margin: 30px auto;
    }
  }
  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .chatForm a {
    color: $ctaColor;
    text-decoration: underline;
    font-family: $circularBold;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 5px;
  }
  form {
    width: 100%;
    max-width: 575px;
    padding: 20px;
    margin: auto;
    input {
      padding: 12px 16px;
    }
    textarea {
      width: 100%;
      min-height: 100px;
      outline: none;
    }
    textarea + div {
      margin-top: -3px;
    }
    label {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    button {
      background-color: transparent;
      color: $ctaColor;
      padding: 16px;
      min-width: 200px;
      font-family: $circularBold;
      border: 1px solid $ctaColor;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-size: 15px;
    }
    .btnLink {
      border: none;
      background-color: transparent;
      padding: 8px 16px;
      margin-top: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin: 20px auto 110px;
    padding: 0 20px;
  }
}
