/* General Colours Variables */
$circular: "montserrat";
$circularStd: "montserrat-medium";
$circularBold: "montserrat-bold";
$circularLight: "circular-light";

@font-face {
  font-family: $circularStd;
  src: url("../assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: $circularBold;
  src: url("../assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: $circularLight;
  src: url("../assets/fonts/CircularStd-Book.ttf");
}

@font-face {
  font-family: $circular;
  src: url("../assets/fonts/Montserrat-Medium.ttf");
}

//Common colors
$black: #000000;
$white: #ffffff;
$dark: #1b1b1b;
$red: #fc3256;
$error: #ff4949;
$bbError: #e41164;
$success: #32ad47;
$spotify: #1db954;
$purple: #8469ff;
$darkPurple: #26223c;
$darkYellow: #d2aa4f;
$updateMsg: #eb33fd;

$primary: #06ffb3;
$primaryDisabled: #b4ffe8;
$secondary: #310ae3;
$medium: #383838;
$grey: #999999;
$light: var(--light);
$veryLight: #f1f1f1;

$primaryColor: var(--primary-color);
$greyColor: #e3e3e3;
$darkGreyColor: var(--dark-grey-color);
$btnDeactivated: #b5b5b5;
$btnDeactivatedText: #5d5d5d;
$accentColor: #ffba66;
$successColor: #0bdb1e;

// Theme component variables
$appPrimaryBackgroundColor: var(--app-primary-background-color);
$appHeaderBackgroundColor: var(--app-header-background-color);
$appHeaderFontColor: var(--app-header-font-color);
$appAccentFontColor: var(--accent-font-color);
$ctaColor: var(--cta-color);

$sidebarPrimaryBackgroundColor: var(--sidebar-primary-background-color);
$sidebarHeaderFooterColor: var(--sidebar-header-footer-color);
$sidebarFontColor1: var(--sidebar-font-color-1);
$sidebarFontColor2: var(--sidebar-font-color-2);
$sidebarTooltipIconColor: var(--sidebar-tooltip-icon-color);

$progressAccentColor: var(--progress-accent-color);
$progressUpdateColor: var(--progress-update-color);

$tooltipBackgroundColor: var(--tooltip-background-color);
$tooltipFontColor: var(--tooltip-font-color);
$alertBackgroundColor: var(--alert-background-color);
$alertFontColor: var(--alert-font-color);

$sliderColor: var(--slider-color);
$helpTextColor: var(--help-text-color);

:export {
  black: $black;
  grey: $grey;
}
