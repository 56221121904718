@import "../../../styles/variables.scss";

.container {
  padding: 30px;
  width: 100%;
  max-height: calc(100vh - 111px);
  background-color: $white;
  overflow-y: auto;
  padding-bottom: 90px;
  @media screen and (max-width: 1440px) {
    max-height: calc(100vh - 84px);
  }
  @media screen and (max-width: 1366px) {
    max-height: calc(100vh - 66px);
  }
  @media screen and (max-width: 767px) {
    padding: 30px 10px;
    margin-bottom: 0;
    max-height: unset;
    border: 8px solid $dark;
    height: auto;
  }
}

.detailsContainer {
  max-width: 650px;
  width: 100%;
  margin: auto;
  form {
    & > div {
      padding: 15px 0 0 !important;
      margin-bottom: 0 !important;
      position: relative;
    }
    .borderBottom {
      border-bottom: 1px solid $light;
    }

    button {
      &.delete {
        background-color: $white;
        color: $red;
        border: 1px solid $red;
        &:disabled {
          background-color: $white;
          color: $red;
        }
      }
    }
  }
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 15px;
  border-bottom: 1px solid $light;
  & > div:last-child {
    text-align: right;
    margin-bottom: 12px;
    p {
      font-size: 15px;
      margin: 0;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: unset;
    margin-top: 70px;
    & > div:first-child {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $dark;
    }
    & > div:last-child {
      text-align: unset;
      p {
        margin-bottom: 8px;
      }
    }
  }
}

.detailsBody {
  display: flex;
  justify-content: space-between;

  & > .profileEdit {
    flex: 0.2 1 auto;
  }
  svg {
    color: $grey;
  }
  .formContainer {
    flex-grow: 1;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    & > .formContainer {
      margin-right: 0;
    }
  }
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  button {
    padding: 18px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $ctaColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &.primary {
      border: 1px solid $ctaColor;
    }
    &.secondary {
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-align: center;
    font-family: $circular;
    letter-spacing: 0.76px;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 1;
  backdrop-filter: blur(4px);
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    padding: 0 5px;
    justify-content: space-between;
    margin: 15px 10px;
  }
}

.title h1 {
  color: $primaryColor;
  font-family: $circular;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: -2.25px;
  margin-right: 15px;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    letter-spacing: -1px;
    color: $white;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
}

.subtitle {
  font-size: 20px;
  margin-left: 5px;
  letter-spacing: -0.38px;
  width: 100%;
  line-height: 18px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    display: none;
  }
}

.customFileUpload {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 144px;
  margin: 40px 35px;
  text-align: center;
  input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    margin-top: 10px;
  }
  button {
    position: relative;
    height: 144px;
    width: 144px;
    background-color: $light;
    border: 1px solid $grey;
    color: $dark;
    font-size: 24px;
    font-weight: 900;
    border-radius: 72px;
  }
  img {
    position: relative;
    height: 144px;
    width: 144px;
    background-color: #e6e6e6;
    border: 1px solid $grey;
    border-radius: 72px;
  }
  .errorText {
    cursor: default;
    position: absolute;
    width: max-content;
    color: $error;
    font-size: 80%;
    bottom: 0;
    transform: translateY(15px);
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    position: relative;
    transform: unset;
    right: unset;
    button {
      height: 105px;
      width: 105px;
    }
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.textLnk {
  font-size: 14px;
  text-decoration: underline;
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    color: $primaryColor;
    text-decoration: underline;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.formContainer {
  .infoIcon {
    display: flex;
    position: absolute;
    transform: translate(150%, 160%);
    color: $white;
    right: 0;
    margin-left: 10px;
    z-index: 1;
    @media screen and (max-width: 767px) {
      transform: translate(0, 0);
    }
  }

  button {
    background: transparent;
    border: 1px solid $ctaColor;
    color: $ctaColor;
    border-radius: 2px;
    min-width: 300px;
    @media screen and (max-width: 767px) {
      min-width: 90px;
    }
  }
}
