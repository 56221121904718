@import "../../styles/variables.scss";

.container {
  position: relative;
}

.container input {
  padding-right: 60px;
}

.icon {
  position: absolute;
  cursor: pointer;
  font-size: 22px;
  transform: translateY(50%);
  right: 25px;
  color: $darkGreyColor;
}

.iconBg {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 24px;
  background-position: 6px 50%;
  cursor: auto;
  padding-left: 50px !important;
}

.buttonClass {
  background-color: $greyColor;
}

.containerClass {
  box-shadow: none;
  outline: none;
  .dropdownClass {
    color: $black;
    box-shadow: 0px 5px 8px -1px rgba($color: $black, $alpha: 0.2);
    width: 353px;
    border: 1px solid $light;
    background-color: #f9f9f9;
    margin: 2px 0 0 0;
    li {
      margin: 0 10px;
      &:hover {
        background-color: $light !important;
      }
    }
    @media screen and (max-width: 767px) {
      width: 530px;
    }
    @media screen and (max-width: 567px) {
      width: calc(100vw - 36px);
    }
  }
  > div:last-child {
    background-color: transparent !important;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
    > div {
      background-color: transparent !important;
      width: 70px;
      padding-left: 20px;
      & > div > div {
        left: 30px !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.inputClass {
  width: 100%;
  height: auto;
  outline: none;
  font-size: 16px;
  padding-left: 95px !important;
  border: 1px solid $darkGreyColor;
  background-color: $greyColor;
}
