@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  max-width: 100%;
  @media (max-width: 100%) {
    max-width: 765px;
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: calc(100vh - 132px);
    margin-top: 0;
  }
  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $dark, $alpha: 0.6);
      }
    }
  }
  .subContainer {
    text-align: center;
    width: 1025px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-bottom: 20px;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 29px 0;
    }
    @media screen and (max-width: 479px) {
      padding: 0;
    }
  }
}

.title {
  h1 {
    color: $primaryColor;
    font-family: $circular;
    font-weight: 600;
    font-size: 64px;
    line-height: 69px;
    letter-spacing: -3.5px;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}

.artistContainer {
  margin: 16px 0 16px 16px;
  color: $primaryColor;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  .artistDetails {
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
    &.cursorDefault {
      cursor: default;
    }
    p.name {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -1.2px;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .moreInfoText {
      font-size: 14px;
      text-decoration: underline;
      color: $primaryColor;
      cursor: pointer;
      white-space: nowrap;
    }
    .failedSection {
      width: 60%;
    }
    .failedText {
      font-size: 18px;
      letter-spacing: -0.8px;
      margin: 0;
      line-height: 18px;
      color: $error;
      text-align: left;
      cursor: not-allowed;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      svg {
        height: 26px;
        margin-right: 5px;
        path {
          fill: $dark;
        }
      }
      img,
      .altImage {
        opacity: 0.5;
      }
    }
    p.subText {
      font-size: 18px;
      letter-spacing: -0.8px;
      margin: 0;
      line-height: 18px;
      color: $dark;
      text-align: left;
    }
    .profile {
      height: 102px;
      width: 102px;
      border-radius: 51px;
      margin-right: 30px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
        height: auto;
      }
      .altImage {
        width: 100%;
        height: 100%;
        path {
          fill: $dark;
        }
      }
    }
  }
  .pending {
    cursor: not-allowed;
    p.name {
      color: $dark;
    }
    svg {
      height: 26px;
      margin-right: 5px;
      path {
        fill: $dark;
      }
    }
    img,
    .altImage {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 767px) {
    .artistDetails {
      min-width: unset;
      width: 100%;
      margin: 10px 0;
      .profile {
        height: 61px;
        width: 61px;
        margin-right: 20px;
      }
      p.name {
        font-size: 18px;
      }
      p.subText {
        font-size: 15px;
      }
    }
    .pending {
      svg {
        height: 18px;
      }
    }
  }
}

.linkArtist {
  display: flex;
  text-align: left;
  color: $white;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1004px;
  padding: 30px 10px;
  margin-bottom: 75px;
  cursor: pointer;
  .contactUs {
    text-decoration: underline;
    cursor: pointer !important;
    font-size: 14px;
    margin: 0;
  }
  &:hover {
    color: rgba($color: $white, $alpha: 0.6);
    .subText {
      color: rgba($color: $white, $alpha: 0.6);
    }
    a {
      color: rgba($color: $white, $alpha: 0.6);
    }
  }
  > div:first-child {
    height: 102px;
    width: 102px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 600;
    margin-right: 30px;
  }
  p.name {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1.2px;
    margin: 0;
  }
  p.subText {
    font-size: 18px;
    margin: 0;
    line-height: 18px;
    color: $white;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
  @media (max-width: 1025px) {
    max-width: 765px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    background-color: $primaryColor;
    color: $white;
    text-align: left;
    padding: 30px 5%;
    margin-bottom: 0;
    &:hover {
      color: rgba($color: $white, $alpha: 0.6);
      .subText {
        color: rgba($color: $white, $alpha: 0.6);
      }
      a {
        color: rgba($color: $white, $alpha: 0.6);
      }
    }
    a {
      color: $white;
    }
    > div:first-child {
      height: 61px;
      width: 61px;
      font-size: 28px;
      color: $white;
      margin-right: 25px;
    }
    p.name {
      font-size: 18px;
    }
    p.subText {
      font-size: 15px;
      color: $white;
    }
  }
}

.disabledPoint {
  cursor: not-allowed;
}
.infoPopupContent {
  padding: 30px;
  a {
    color: $dark;
    text-decoration: underline;
  }
}
