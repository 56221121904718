@import "../../../styles/variables.scss";

.policyContent {
  text-align: left;
  width: 100%;
  background-color: $white;
}

h1,
h2 {
  font-family: $circular;
  letter-spacing: -2.5px;
}
.content {
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  padding-top: 50px;
  padding-bottom: 90px;
  max-width: 1100px;
  margin: 0px auto;
  @media (max-width: 1025px) {
    max-width: 765px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
  }
  @media (max-width: 768px) {
    max-width: 573px;
  }
  @media (max-width: 639px) {
    max-width: 85%;
  }
}
//Error 404 page style start
.errorContainer {
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
  height: 100vh;
  padding: 10% 0;
  h1 {
    color: $primaryColor;
    font-size: 72px;
    font-weight: 900;
    font-family: $circular;
  }
  h3 {
    color: $primaryColor;
    font-weight: 900;
  }
  .homeLink {
    font-size: 18px;
    font-weight: 500;
    color: $medium;
    text-decoration: underline;
    margin: 45px 0;
  }
}
// Error 404 page style end
.content li {
  font-weight: bold;
  cursor: pointer;
}

.content a {
  text-decoration: underline;
  cursor: pointer;
  word-break: break-all;
}

p {
  font-family: $circularStd;
}
.title {
  color: $primaryColor;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: -2.25px;
  margin: 30px 0px;
}
.whyBeatbreadContainer {
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding-bottom: 10%;
}
.titleBeatbread {
  width: 100%;
  h1 {
    color: $primaryColor;
    font-family: $circular;
    font-weight: 600;
    padding-bottom: 35px;
    padding-top: 55px;
    text-align: center;
    font-size: 64px;
    line-height: 69px;
    letter-spacing: -3.01875px;
    white-space: nowrap;
    @media (max-width: 1025px) {
      font-size: 60px;
      margin: 0 10%;
    }
    @media (max-width: 992px) {
      font-size: 48px;
      margin: 0 10%;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      white-space: unset;
    }
    @media (max-width: 639px) {
      font-size: 32px;
      margin: 0 10px;
      white-space: unset;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}
li,
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.wrapperGrey {
  background-color: #f7f7f7;
  padding: 20px 0 20px 0;
  width: 100%;
}

.get {
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
  color: $primaryColor;
}

.get div {
  float: left;
  width: 23%;
  text-align: center;
}

.get svg {
  width: 60px;
  display: block;
  margin: 0px auto;
  position: relative;
  top: -10px;
}

.get svg g {
  fill: $primaryColor;
}

.get span {
  text-align: center;
  font-size: 14px;
}
.wrapperBlue {
  background-color: $primaryColor;
  padding: 20px 0;
  width: 100%;
}
.howitworks {
  /*background-image: url("../../../assets/logos/product.png");*/
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: contain;
  width: 100%;
  padding: 5%;
  padding-bottom: 0px;
}

.howitworks p {
  float: left;
  text-align: left;
  margin: 0% 2% 0% 0%;
  width: 38%;
  font-size: 14px;
  color: $white;
  font-weight: normal;
}

.howitworks h2 {
  color: $accentColor;
  font-size: 36px;
  width: auto;
  text-align: left;
}

.howitworks iframe {
  width: 50%;
  height: 250px;
  position: relative;
  top: -40px;
  @media screen and (max-width: 767px) {
    position: static;
    width: 100%;
  }
}

.comparison {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.comparison table {
  margin: 0px auto;
  border-spacing: 0px;
}
.comparisonTable {
  font-size: 16px;
}
.comparison th,
.comparison td {
  border-bottom: 1px solid #ddd;
  height: 40px;
  text-align: center;
  width: 15%;
  color: #999;
}

.comparison tr {
  border-left: 1px solid #e9e9e9;
}

.comparison th {
  color: $white;
  background-color: $primaryColor;
}

.comparison tr th:first-child {
  color: $white;
  background-color: $white;
}

.comparison tr td:first-child {
  width: 30%;
  text-align: left;
  color: $black;
}

.sep > td {
  height: 20px;
}

.table_section td {
  font-weight: bold;
  background: #f2f2f2;
  border: 0px solid;
}
.yesCell {
  color: rgb(51, 51, 51);
  background-color: rgb(165, 224, 255);
}

.faqs {
  padding: 30px 0;
}

.faqs h2 {
  margin: 20px 0;
  font-size: 36px;
  color: $primaryColor;
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: $white !important;
  color: $primaryColor !important;
  cursor: pointer;
  padding: 18px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline-color: transparent !important;
  font-size: 20px;
  font-weight: bold;
  border-top: 0 !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.collapsible:hover {
  border-bottom: 1px solid $primaryColor !important;
}

/* Style the collapsible content. Note: hidden by default */
.collapsibleContent {
  padding: 0 18px;
  background-color: $white;
  text-align: left;
  margin: 16px 0;
  font-weight: 400;
  font-size: 15px;
}
.testimonial {
  width: 30%;
  height: auto;
  position: relative;
  top: 120px;
  left: 5%;
  text-align: left;
  font-family: "DM Serif Display", serif;
}
.testimonial .quote {
  font-family: $circular;
  font-size: 22px;
  font-style: italic;
}
.spotlight {
  background-size: cover;
  height: 70%;
}
.spotlight .testimonial {
  background: $primaryColor;
  color: $white;
  font-size: 24px;
  padding: 20px;
  position: relative;
  top: 20%;
  left: 0px;
  display: inline-block;
  margin-top: 50px;
}

.jones {
  width: 100%;
  height: 500px;
  position: relative;
  background-image: url("../../../assets/logos/jones.jpg");
}
.jones .testimonial {
  right: 0;
  width: 350px;
  margin-left: auto;
  margin-right: 50px;
  position: absolute;
}
.ryno {
  width: 100%;
  background-image: url("../../../assets/logos/ryno-1.jpg");
}
.rynoContent {
  left: -36% !important;
}
@media only screen and (max-width: 767px) {
  .comparison {
    width: 100%;
    overflow: scroll;
    padding-left: 0;
    padding-right: 0;
    margin: 5%;
    margin-left: 0;
    margin-right: 0;
  }
  .comparison table {
    width: 100%;
  }
  .comparison th,
  .comparison td {
    height: 75px;
  }
  .comparison th {
    padding: 10px;
    @media only screen and (max-width: 410px) {
      padding: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50px;
    }
  }
  .testimonial {
    position: static;
    margin: 5%;
    margin-top: 10%;
    width: 90%;
  }
  .get {
    display: unset;
  }
  .get div {
    width: 43%;
    margin: 3.25%;
  }
  .get > span {
    width: 70%;
    float: none;
  }
  .howitworks {
    width: 90%;
    padding: 5%;
    padding-bottom: 0px;
  }
  .howitworks p {
    width: 90%;
    float: none;
    padding: 5%;
  }
  .spotlight .testimonial {
    background: $primaryColor;
    color: $white;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    margin-top: 10%;
    width: 50%;
  }
  .jones .testimonial,
  .ryno .testimonial {
    left: 0 !important;
    width: 80%;
    position: relative;
    margin-top: 230px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: 15px;
    }
  }
  .spotlight {
    background-position: 50% 0%;
  }
  .faqs {
    width: 90%;
    padding: 5%;
  }
}
