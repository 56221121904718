@import "../../styles/variables.scss";

.afterLoginHamburger {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    & > div {
      align-items: center;
      text-align: center;
    }
  }
  a {
    color: $white;
  }
  a:hover {
    color: $white;
  }
}
.hamburgerIcon {
  padding-right: 15px;
  display: flex;
  align-items: center;
  fill: $white;
}
.spanLink {
  color: $white;
  cursor: pointer;
  padding: 20px 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  opacity: 1;
}

.borderBottom {
  border-bottom: 2px solid $white;
}

@media screen and (max-width: 767px) {
  .menuOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: $white, $alpha: 0.4);
    transition: background-color 0.3s;
    width: 100%;
    z-index: 2;
    &.dNone {
      width: 0;
      background-color: rgba($color: $white, $alpha: 0);
    }
  }
  .visible {
    display: flex !important;
  }
}
