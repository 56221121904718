@import "../../styles/variables.scss";

.upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  .title {
    margin-bottom: 32px;
    color: $medium;
  }
  .help {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $helpTextColor;
    font-size: 18px;
    cursor: pointer;
    font-family: $circularBold;
    letter-spacing: 0.76px;
    @media screen and (max-width: 1366px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    .textContent {
      margin-left: 32px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 20px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.56px;
      }
      h3 {
        font-weight: 700;
      }
    }
    .dropContainer {
      text-align: center;
    }
    .files {
      align-items: flex-start;
      justify-items: flex-start;
      flex: 1;
      overflow-y: auto;
      > div {
        margin: auto;
      }
      .row {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        overflow: hidden;
        box-sizing: border-box;
        .fileName {
          margin-bottom: 8px;
          font-size: 18px;
          color: $dark;
          word-break: break-all;
          .msgError {
            display: flex;
            align-items: center;
            color: $bbError;
            font-size: 16px;
            float: right;
            margin-left: 15px;
            .helpBtn {
              color: $primaryColor;
              font-size: 18px;
              margin-left: 8px;
              cursor: pointer;
            }
            @media screen and (max-width: 1366px) {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 1366px) {
            font-size: 14px;
          }
        }
        .progressWrapper {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
        }
        .checkIcon {
          opacity: 0.5;
          margin-left: 10px;
          line-height: 18px;
          cursor: pointer;
          font-size: 24px;
          display: inline-block;
          transform: translateY(4px);
          @media screen and (max-width: 1366px) {
            font-size: 20px;
            transform: translateY(2px);
          }
        }
      }
      .fileCount {
        text-align: center;
        margin-top: 18px;
        margin-bottom: 0;
        font-family: $circularBold;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .actions {
    display: flex;
    flex: 1;
    width: 100%;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
}

.btn {
  font-size: 14px;
  display: inline-block;
  height: 36px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: $primaryColor;
  color: $white;
  outline: 0;
}

.btn:disabled {
  background: $btnDeactivated;
  cursor: default;
}
.uploadButtons {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  padding: 15px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;
  .actionCheck {
    text-align: left;
    font-size: 16px;
    margin: 0 10px;
    margin-bottom: -10px;
    margin-top: -10px;
    span {
      font-size: 14px;
    }
    .checkbox {
      color: $primaryColor !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .uploadReportsButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    .quickLinksContainer {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      width: 350px;
      @media screen and (max-width: 1024px) {
        margin-left: 10px;
      }
      > p {
        position: absolute;
        top: 10px;
        font-size: 14px;
        letter-spacing: -0.34px;
        line-height: 13px;
        span {
          @media screen and (max-width: 1366px) {
            display: none;
          }
          @media screen and (max-width: 1440px) {
            font-size: 12px;
          }
        }
      }
      .selectContainer {
        display: flex;
        align-items: center;
        & > div {
          width: 200px;
          height: 50px;
          flex-grow: 1;
          @media screen and (max-width: 767px) {
            width: 130px;
          }
        }
        button {
          width: 80px;
          border: 1px solid $ctaColor;
          margin: 0;
          margin-left: 5px;
          &:disabled {
            border: 1px solid $darkGreyColor;
          }
        }
      }
    }
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      margin: 10px 5px 5px;
      color: $ctaColor;
      &.secondary {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
      }
      @media screen and (max-width: 1440px) {
        margin: 5px;
      }
      @media screen and (max-width: 767px) {
        margin: 5px;
      }
    }
    &.dummyBtnContainer {
      width: 100%;
      button {
        font-size: 18px;
        max-width: fit-content;
        width: unset;
        margin: 0 10px;
        &.secondary {
          border: none;
          text-decoration: underline;
          color: $primaryColor;
        }
        &.demoBtn {
          background-color: $primaryColor;
          font-family: $circularBold;
          color: $white;
          border: none;
          border-radius: 4px;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 40px;
            margin-right: -18px;
            stroke: $white;
            @media screen and (max-width: 767px) {
              width: 35px;
              margin-right: -10px;
            }
          }
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 14px;
          padding: 10px 15px;
        }
        @media screen and (max-width: 767px) {
          margin: 5px;
          max-width: unset;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 10px;
      }
    }
    button:disabled {
      border-color: $grey;
      color: $grey;
      cursor: not-allowed;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 15px 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoPrimary {
    background-color: $helpTextColor;
    color: $white;
    margin-right: 15px;
    margin-left: 0;
    @media screen and (max-width: 1366px) {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}
