@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 111px);
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $light;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  max-width: 100%;
  @media screen and (max-width: 1440px) {
    height: calc(100vh - 84px);
  }
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 66px);
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: unset;
    margin-top: 0;
  }
  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $primaryColor, $alpha: 0.6);
      }
    }
  }
  .subContainer {
    text-align: center;
    width: 100%;
    margin: 0px auto;
    padding: 50px 40px;
    align-items: flex-start;
    height: calc(100vh - 111px);
    overflow-y: auto;
    .filterBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: $white;
      margin: 20px 0;
      flex-wrap: wrap;
      & > div {
        align-items: center;
        display: flex;
        text-align: left;
        padding: 20px;
        & > div {
          margin: 0 20px;
          width: 200px;
        }
        label {
          margin: 0;
        }
      }
      h4 {
        margin: 0;
        margin-right: 10px;
        font-size: 20px;
      }
      p {
        margin: 0 10px 0 0;
      }
      @media screen and (max-width: 767px) {
        h4 {
          font-size: 18px;
        }
      }
    }
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 84px);
    }
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 66px);
    }
    @media screen and (max-width: 1080px) {
      min-width: unset;
    }
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 66px);
      height: unset;
      padding: 0 10px;
      min-width: 100%;
    }
    @media screen and (max-width: 479px) {
      padding: 0;
      margin: 0;
      min-width: 100%;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  h1 {
    color: $primaryColor;
    font-weight: 600;
    font-size: 54px;
    line-height: 69px;
    letter-spacing: -3.5px;
    font-family: $circular;
    white-space: unset;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  h4 {
    position: absolute;
    right: 180px;
    cursor: pointer;
    padding-right: 10px;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 1080px) {
      right: 5%;
    }
    @media screen and (max-width: 767px) {
      top: 15px;
      right: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    &.innerTitle {
      background-color: $light;
      justify-content: center;
      h1 {
        display: block;
        font-size: 36px;
      }
    }
  }
}

.artistContainer {
  color: $primaryColor;
  flex-direction: column;
  display: block;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;

  th {
    font-size: 18px;
    font-family: $circularBold;
    color: $grey;
  }
  td {
    vertical-align: baseline !important;
    font-family: $circular;
    font-size: 15px;
  }

  .artistDetails {
    display: flex;
    float: left;
    text-align: left;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
    p.name {
      font-size: 28px;
      font-weight: 600;
      letter-spacing: -1.2px;
      margin: 0;
      display: inline-block;
      align-items: center;
      vertical-align: top;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    p.subText {
      font-size: 18px;
      letter-spacing: -0.8px;
      margin: 0;
      padding: 6px 0;
      line-height: 18px;
      color: $dark;
      text-align: left;
      cursor: pointer;
      text-decoration: underline;
    }
    .profile {
      border-radius: 51px;
      overflow: hidden;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      img {
        width: 100%;
        height: 100%;
      }
      .altImage {
        width: 100%;
        height: 100%;
        path {
          fill: $dark;
        }
      }
    }
    @media screen and (max-width: 1080px) {
      padding: 0;
    }
  }
  .pending {
    cursor: default;
    p.name {
      color: $dark;
    }
    svg {
      height: 26px;
      margin-right: 5px;
      path {
        fill: $dark;
      }
    }
    img,
    .altImage {
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    .artistDetails {
      min-width: 100%;
      width: 100%;
      padding-left: 0px;
      margin: 30px 0;
      .profile {
        height: 61px;
        width: 61px;
        margin-right: 20px;
      }
      p.name {
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        width: 190px;
        text-overflow: ellipsis;
      }
      p.subText {
        font-size: 15px;
      }
    }
    .pending {
      svg {
        height: 18px;
      }
    }
  }
}

.linkArtist {
  display: flex;
  text-align: left;
  color: $white;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1076px;
  padding: 30px 0;
  cursor: pointer;
  &:hover {
    color: rgba($color: $white, $alpha: 0.6);
    .subText {
      color: rgba($color: $white, $alpha: 0.6);
    }
    a {
      color: rgba($color: $white, $alpha: 0.6);
    }
  }
  > div:first-child {
    height: 102px;
    width: 102px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 600;
    margin-right: 30px;
  }
  p.name {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -1.2px;
    margin: 0;
  }
  p.subText {
    font-size: 18px;
    margin: 0;
    line-height: 18px;
    color: $white;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
  @media (max-width: 1025px) {
    max-width: 765px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
  }
  @media (max-width: 768px) {
    max-width: 573px;
  }
  @media (max-width: 639px) {
    max-width: 100%;
    align-items: flex-start;
    text-align: left;
    margin-left: 5%;
    color: $dark;
    &:hover {
      color: rgba($color: $dark, $alpha: 0.6);
      .subText {
        color: rgba($color: $dark, $alpha: 0.6);
      }
      a {
        color: rgba($color: $dark, $alpha: 0.6);
      }
    }
    a {
      color: $dark;
    }
    > div:first-child {
      height: 61px;
      width: 61px;
      font-size: 28px;
      color: $white;
    }
    p.name {
      font-size: 18px;
    }
    p.subText {
      font-size: 15px;
      color: $dark;
    }
  }
}

.disabledPoint {
  color: $grey;
  cursor: not-allowed !important;
  pointer-events: none;
}
.toggleSwitch {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  & > * {
    flex-shrink: 0;
  }
}
.toggleSwitch > div {
  display: flex;
  align-items: center;
  i {
    font-size: 18px;
    cursor: pointer;
  }
  p {
    font-size: 18px;
    display: inline-block;
    min-width: 25px;
    margin: 0;
    text-align: center;
  }
}
.resendLink {
  p.subText {
    font-size: 18px;
    letter-spacing: -0.8px;
    font-weight: 600;
    margin: 0;
    padding: 6px 0;
    line-height: 18px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    color: $primaryColor;
  }
}
.artistRow {
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    padding: 0;
    margin: unset;
  }
}

.adminPagination {
  display: inline-block;
  margin: 0 auto;
}

.adminPagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    padding: 6px 10px;
  }
}

.adminPagination li.active a {
  background-color: $primaryColor;
  color: white;
  border: 1px solid $primaryColor;
}

.adminPagination li:hover:not(.active) a {
  background-color: #ddd;
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  button {
    padding: 18px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $ctaColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &.primary {
      border: 1px solid $ctaColor;
      &:disabled {
        border: 1px solid $grey;
        color: $grey;
        cursor: not-allowed;
      }
    }
    &.secondary {
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-align: center;
    font-family: $circular;
    letter-spacing: 0.76px;
    .primaryName {
      text-transform: capitalize;
      color: $primaryColor;
      white-space: nowrap;
    }
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  .changeSource {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .changeTo {
      display: flex;
      align-items: center;
      white-space: nowrap;
      & > div {
        min-width: 200px;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 1;
  backdrop-filter: blur(4px);
}

.deleteModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 500px;
  width: fit-content;
  background-color: $white;
  margin: auto;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  .modalContainer {
    padding: 20px 45px;
  }
  .artistList {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      p {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: $dark;
      }
      .deleteIcon {
        cursor: pointer;
        padding: 0 20px;
        margin-left: auto;
        margin-right: 0;
      }
    }

    .profile {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      margin-right: 15px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: auto;
      }
      .altImage {
        width: 100%;
        height: 100%;
        path {
          fill: $dark;
        }
      }
    }
  }
  button {
    padding: 18px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $ctaColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &.primary {
      border: 1px solid $ctaColor;
    }
    &.secondary {
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.86px;
    color: $primaryColor;
    width: 100%;
    word-break: break-all;
    border-bottom: 1px solid $light;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin: auto;
    button {
      &:first-child {
        margin-right: 20px;
      }
      &.filled {
        background-color: $ctaColor;
        color: $white;
        border: 1px solid $ctaColor;
        &:disabled {
          cursor: not-allowed;
          background-color: $btnDeactivatedText;
          border: 1px solid $btnDeactivatedText;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    min-width: unset;
    width: 90%;
    .modalContainer {
      padding: 20px;
    }
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
  }
}

.popover {
  border-radius: 0;
  .titlePopover {
    font-weight: 600;
    font-size: 15px;
    color: $dark;
    text-align: center;
  }
  .btnPopover {
    display: flex;
    button {
      padding: 8px 30px;
      font-weight: bold;
      color: $white;
      border: none;
      margin: auto;
      &:first-child {
        background-color: $primaryColor;
      }
      &:last-child {
        background-color: $grey;
      }
    }
  }
}
.artistOptions {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: $dark;
  }
  .viewIp {
    padding-left: 15px !important;
  }
}
.ipList {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: $dark;
}

.slugContainer {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  .slugRow {
    display: flex;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
    .profile {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: $dark;
        }
      }
    }
    p {
      margin: 0;
      font-size: 24px;
      color: $primaryColor;
      text-decoration: underline;
    }
    .toggleSlug {
      display: flex;
      margin: 0 20px 0 auto;
    }
  }
}

// list tabular view
.tableProfile {
  img {
    vertical-align: text-top;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .altImage {
    vertical-align: text-top;
    width: 50px;
    height: 50px;
    path {
      fill: $dark;
    }
  }
}
.tableUsernameContainer {
  display: flex;
  flex-direction: column;
  .artistDetails {
    margin-bottom: 0px;
  }
}
.tableIpLoginContainer {
  display: flex;
  flex-direction: column;
}
.toggleSwitch label {
  margin-bottom: 5px;
  margin-top: -10px;
  padding: 11px;
  span {
    width: 18px !important;
    height: 18px !important;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  .edit {
    margin-left: 30px;
    cursor: pointer;
  }
}

// artist view
.artistActions {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: $primaryColor;
  cursor: pointer;
  & > * {
    margin-left: 5px;
  }
  :nth-child(1) {
    margin-left: 0px;
  }
  p {
    margin: 0;
  }
  .offerCallBtn {
    border: none;
    color: $dark;
    font-size: 14px;
    background-color: transparent;
    padding: 0;
    &:disabled {
      cursor: not-allowed;
      color: $grey;
    }
  }
  .cancelIcon {
    font-size: 19px;
    position: relative;
    &::after {
      content: "\00D7";
      font-size: 29px;
      position: absolute;
      font-family: $circularStd;
      top: 2px;
      left: 6px;
      font-weight: 600;
    }
  }
}
.reportListContainer {
  max-height: 300px;
  overflow-y: auto;
  table {
    width: 100%;
    color: $primaryColor;
    td,
    th {
      padding: 4px 8px;
    }
    tr td:last-child {
      cursor: pointer;
    }
    tr.deleted {
      color: $grey;
      td:last-child {
        cursor: unset;
      }
    }
  }
}
.singleReportContainer {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  div:first-child {
    width: 80%;
  }
  li {
    color: $primaryColor !important;
    font-family: $circular;
    font-size: 19px;
    font-weight: 400;
    cursor: default;
    overflow-wrap: break-word;
  }
  svg {
    cursor: pointer;
    color: $primaryColor;
  }
}
.noReports {
  color: $primaryColor !important;
  font-family: $circular;
  font-size: 20px;
  font-weight: 400;
}

.artistProfile {
  img {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .altImage {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    path {
      fill: $dark;
    }
  }
}
.artistTable {
  th {
    font-family: $circularBold;
    font-size: 15px;
    color: $grey;
  }
  td {
    font-family: $circular;
    font-size: 15px;
    border: none !important;
    vertical-align: middle !important;
  }
  .offerInfo {
    p {
      margin: 0;
    }
  }
}
.noArtists {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryColor;
  font-family: $circular;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  p {
    margin: 0 !important;
  }
}

.sourceDisabled {
  td,
  td div,
  svg {
    color: $grey !important;
  }
  label {
    background: $grey !important;
  }
}

.userAccountDeactivated {
  svg {
    color: $grey !important;
  }
}
