@import "../../../../styles/variables.scss";

.mainContainer {
  text-align: center;
  width: 100%;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 45px;
  max-width: 1076px;
  @media (max-width: 1025px) {
    max-width: 765px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    min-height: calc(100vh);
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh);
  }
}
.mainContainer h1 {
  color: $primaryColor;
  font-weight: 600;
  font-size: 64px;
  line-height: 69px;
  letter-spacing: -3.01875px;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 48px;
    text-align: left;
    padding: 0 20px;
  }
  @media screen and (max-width: 479px) {
    white-space: unset;
    font-size: 40px;
  }
}

.formContainer {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 511px;
  padding: 79px 0;
  align-items: center;
}
.loginContent .emailorphone {
  margin: -4px 3px;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $grey;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.sociallabelText {
  margin: 36px 0px 0px -1px;
  letter-spacing: -0.41px;
  padding: 0px !important;
  line-height: initial;
}
.socialMediaLink {
  display: flex;
  align-items: center;
  padding: 22px 16px;
  text-align: left;
  color: $white;
  background-color: $medium;
  margin: 6px -1px;
  float: left;
  width: 100%;
  vertical-align: middle;
}

.labelText {
  letter-spacing: -0.41px;
}
.next {
  background-color: #b6b6b6 !important;
}
.socialYoutube {
  padding-top: 10px;
}
.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  .profile {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: -0.79px;
    font-size: 18px;
    margin: 0;
  }
  button {
    background-color: $dark;
    padding: 8px 20px;
    width: auto;
    color: $white;
    flex: 0 0 auto;
    border-radius: 2px;
  }
  button:hover {
    color: $grey;
  }
}

.noOption {
  text-align: center;
  color: $grey;
}

.formMargin0 div {
  margin-bottom: 0 !important;
}

.spotifySearch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 6px;
}

.spotifySearch > * {
  flex-shrink: 0;
}

.spotifySearch > div {
  width: 100%;
}

.spotifySearch > i {
  color: $spotify;
  position: absolute;
  background: radial-gradient(
    $white,
    $white,
    $white,
    $white,
    $white,
    transparent,
    transparent,
    transparent,
    transparent
  );
  border-radius: 50px;
  font-size: 30px;
  transform: translateX(-19px);
}
.emailorphone {
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  &:hover {
    color: $primaryColor;
    text-decoration: underline;
  }
}

.subtitle {
  color: $medium;
}
.title {
  h1 {
    color: $primaryColor;
    font-weight: 600;
    font-size: 64px;
    line-height: 69px;
    font-family: $circular;
    letter-spacing: -3.01875px;
    white-space: nowrap;
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}
.hideContent {
  display: none;
}
