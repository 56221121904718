@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1500px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $white;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    height: calc(100vh - 198px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 150px);
    }
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 132px);
    }
    @media screen and (max-width: 767px) {
      height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 90px);
    padding: 0px 15px;
    max-height: unset;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 0 0 110px 0;
  form {
    & > div {
      margin-bottom: 0 !important;
    }
    .borderBottom {
      border-bottom: 1px solid $light;
    }
  }
  .totalContent {
    display: flex;
    flex-direction: column;
    .totalNumber {
      font-size: 36px;
      font-family: $circularBold;
      margin: 0;
      color: $primaryColor;
      @media screen and (max-width: 1440px) {
        font-size: 32px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
    .sliderWordings {
      font-size: 18px;
      margin: 20px 0;
      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .digitContainer {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-start;
    .digitContent {
      background-color: $sidebarPrimaryBackgroundColor;
      padding: 10px;
      border-radius: 5px;
      font-family: $circularBold;
      color: $sidebarFontColor1;
      font-size: 22px;
      margin-right: 30px;
      flex-shrink: 0;
      margin-bottom: 10px;
      td {
        padding: 8px 12px;
        padding-left: 20px;
        & > div {
          font-family: $circularStd;
          font-weight: 500;
          display: flex;
          font-size: 18px;
          justify-content: space-between;
          @media screen and (max-width: 1440px) {
            font-size: 17px;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
      @media screen and (max-width: 1440px) {
        font-size: 20px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        width: 100%;
      }
    }
    .htmlContent {
      flex-grow: 1;
      position: relative;
      font-size: 14px;
      * {
        box-sizing: initial;
        &:after,
        &:before {
          font-size: initial;
          box-sizing: initial;
        }
      }
      a {
        text-decoration: underline;
        color: -webkit-link;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }
  .customizedContent {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .recoupedContainer {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      p {
        color: $primaryColor;
        font-size: 14px;
        font-family: $circularBold;
        margin: 0;
      }
      ul {
        padding-inline-start: 18px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    .artistMsg {
      position: relative;
      display: flex;
      padding: 30px 40px;
      flex-grow: 1;
      background-image: linear-gradient(
        to bottom right,
        $primaryColor calc((0.15 - 1) * 500%),
        transparent calc(0.15 * 650%)
      );
      i {
        font-family: monospace;
        font-style: normal;
        position: absolute;
        top: -18px;
        left: -18px;
        height: 45px;
        width: 45px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        background: white;
        box-shadow: 0 0 6px $darkGreyColor;
        color: $primaryColor;
        align-items: center;
        font-weight: 600;
        font-size: 15px;
        @media screen and (max-width: 767px) {
          height: 35px;
          width: 35px;
          font-size: 14px;
          top: -12px;
          left: -12px;
        }
      }
      p {
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 170px;
  }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 32px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 28px;
    letter-spacing: 1.08px;
  }
  @media screen and (max-width: 1366px) {
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    padding: 30px 0 0;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $sidebarPrimaryBackgroundColor;
    margin-right: 15px;
    &::after {
      text-decoration: none;
    }
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  text-decoration: underline;
  text-decoration-color: $darkGreyColor;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.tooltip {
  width: 30%;
  font-size: 14px;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.formMainButtons {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 15px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;

  .buttonContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
    & > div {
      margin: 20px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 767px) {
        margin: 8px 0;
      }
    }
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      color: $ctaColor;
      &.secondary {
        border: none;
        max-width: unset;
        display: flex;
        width: max-content;
        align-items: center;
        font-size: 15px;
        text-decoration: underline;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
        background-color: $ctaColor;
        color: $white;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      margin-top: 3px;
      display: flex;
      align-items: center;
      color: $primaryColor;
      font-family: $circularBold;
    }
    @media screen and (max-width: 767px) {
      width: unset;
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 10px 0;
  }
}

.menuContainer > div {
  max-width: 1500px !important;
}
