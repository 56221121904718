@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1000px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $white;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    letter-spacing: 0.56px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 16px;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 198px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      max-height: calc(100vh - 150px);
    }
    @media screen and (max-width: 1366px) {
      max-height: calc(100vh - 132px);
    }
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 10px 10px 10px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 4px;
  }
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 36px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    padding: 30px 0 0;
  }
  @media screen and (max-width: 1440px) {
    font-size: 27px;
    letter-spacing: 1.08px;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.sendReportsContainer {
  .dataReviewSubHeading {
    width: 80%;
    max-width: 600px;
    p {
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      padding: 10px 0px 20px 0px;
      font-size: 18px;
    }
  }
  .quickLinksContainer {
    width: 100%;
    max-width: 700px;

    p {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      font-family: $circularBold;
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
    .selectContainer {
      margin-top: 10px;
      justify-content: center;
      display: flex;
      & > div {
        width: 100%;
      }
      button {
        color: $ctaColor;
        background-color: transparent;
        border: 1px solid $ctaColor;
        margin-left: 10px;
        padding: 0 20px;
        border-radius: 2px;
      }
      :global {
        div::-webkit-scrollbar {
          width: 6px;
          height: 0px;
        }
        div::-webkit-scrollbar-track {
          background: $white;
        }
        div::-webkit-scrollbar-thumb {
          background: rgba($color: $dark, $alpha: 0.2);
        }
        div::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $dark, $alpha: 0.4);
        }
      }
    }
  }
  .sendReportsSubContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $darkGreyColor;
    margin-top: 20px;
    .reportListContainer {
      margin-top: 20px;
      width: 100%;
      max-width: 700px;
      margin-bottom: 200px;
      p {
        margin-top: 20px;
        font-size: 18px;
        text-align: left;
        font-family: $circularBold;
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 14px;
          letter-spacing: 0.56px;
        }
      }
      .noFiles {
        color: $grey;
        text-align: center;
      }
      table {
        color: $primaryColor;
        width: 100%;
        th {
          text-decoration: underline;
          @media screen and (max-width: 1440px) {
            font-size: 15px;
            letter-spacing: 0.56px;
          }
        }
        td,
        th {
          padding: 5px;
        }
        td:first-child {
          text-align: left;
        }
        td:first-child,
        th:first-child {
          padding-left: 0;
        }
        td:last-child,
        th:last-child {
          text-align: right;
          font-weight: bold;
          padding-right: 0;
          span {
            cursor: pointer;
          }
        }
        td {
          @media screen and (max-width: 1440px) {
            font-size: 14px;
            letter-spacing: 0.56px;
          }
        }
        tr.deleted {
          color: $grey;
        }
      }
      @media screen and (max-width: 1024px) {
        margin-bottom: 160px;
      }
    }
    .subTitle {
      margin: 15px 0;
      font-family: $circularBold;
      text-align: left;
      font-size: 18px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.56px;
      }
    }
    .uploadReportListing {
      display: flex;
      width: 100%;
      margin-bottom: 18px;
      .distList {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .reportContainer {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-right: 5px;
        margin-bottom: 4px;
        border-bottom: 1px solid $light;
        .reportDistributor {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          img {
            height: 44px;
            @media screen and (max-width: 1440px) {
              height: 40px;
            }
            @media screen and (max-width: 767px) {
              height: 36px;
            }
          }
          h2 {
            font-size: 24px;
            font-family: $circularBold;
            letter-spacing: -1.5px;
            margin: 0;
            @media screen and (max-width: 1440px) {
              font-size: 22px;
            }
            @media screen and (max-width: 767px) {
              font-size: 20px;
            }
          }
          i {
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background-color: $light;
            color: $dark;
            margin-left: 15px;
            cursor: pointer;
            @media screen and (max-width: 1440px) {
              font-size: 18px;
              width: 32px;
              height: 32px;
            }
          }
        }
        .reportList {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 16px;
          .fileName {
            display: flex;
            align-items: center;
            background-color: $veryLight;
            padding: 2px 6px;
            border-radius: 2px;
            margin-right: 6px;
            margin-bottom: 4px;
            text-align: left;
          }
          .delete {
            font-size: 24px;
            line-height: 16px;
            margin-left: 10px;
            cursor: pointer;
            @media screen and (max-width: 767px) {
              font-size: 20px;
              line-height: 14px;
            }
          }
          .addMore {
            margin-left: auto;
            font-family: $circularBold;
            text-transform: uppercase;
            color: $primaryColor;
            font-size: 14px;
            cursor: pointer;
            @media screen and (max-width: 1440px) {
              font-size: 13px;
            }
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 1440px) {
            font-size: 14px;
          }
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
    .dropDownContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
        :global {
          .distributor__menu {
            max-height: 150px;
          }
          .distributor__menu-list {
            max-height: 150px;
          }
          .distributor__option {
            padding: 6px 12px;
          }
        }
      }
    }
  }
}

.sentReportsBtns {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  padding: 10px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;
  @media screen and (max-width: 767px) {
    position: fixed;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2%;
      border: 1px solid $ctaColor;
      height: 50px;
      text-align: center;
      background-color: transparent;
      margin: 10px 5px;
      color: $ctaColor;
      &.secondary {
        background-color: transparent;
        color: $ctaColor;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.radioContainer {
  border-bottom: 1px solid $darkGreyColor;
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  p {
    text-align: left;
    font-family: $circularStd;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
      margin-bottom: 10px;
    }
  }
  label {
    @media screen and (max-width: 767px) {
      margin-left: 0 !important;
    }
    span {
      font-family: $circularStd !important;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
  .checkbox {
    color: $primaryColor !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .error {
      width: 100%;
    }
    .containerClass {
      width: 100%;
      margin-top: -20px;
      text-align: left;
      padding: 0;
    }
    .formInfoText {
      width: 100%;
      text-align: left;
      padding: 0;
      color: $grey;
      font-size: 14px;
      margin-bottom: -5px;
      @media screen and (max-width: 1366px) {
        font-size: 13px;
      }
    }
    & > :global(.form-group) {
      margin-right: 60px;
      width: auto;
      @media screen and (max-width: 1024px) {
        margin-right: 30px;
      }
    }
    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 12px 0;
  }
}
.checked {
  span {
    font-weight: bold !important;
  }
}

.mobileModalOverlay {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
  @media screen and (max-height: 700px) {
    align-items: unset;
  }
}

.mobileModal {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1366px;
  text-align: center;
  padding: 20px;
  background-color: $white;
  margin: 20px 20px 60px;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  i {
    margin: 0 auto 10px;
    font-style: normal;
    display: flex;
    color: $white;
    font-weight: 600;
    font-size: 25px;
    background-color: $darkGreyColor;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
  }
  h2 {
    font-size: 30px;
    font-family: $circularBold;
  }
}

.uploadModal {
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 90%;
  background-color: $white;
  margin: 78px auto;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0;
  @media screen and (max-width: 767px) {
    margin: 20px auto 60px;
    padding: 0;
  }
}

.uploadModalContent {
  display: flex;
  align-items: stretch;
  position: relative;
  min-height: 70vh;
  max-height: calc(100vh - 156px);
  .distributorInfo {
    display: flex;
    flex-direction: column;
    padding: 30px 20px 30px 40px;
    flex: 1 0;
    overflow-y: auto;
    h4 {
      font-size: 18px;
      font-family: $circularBold;
      margin: 20px 0;
    }
    .logoDetails {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img.logo {
        height: 50px;
        @media screen and (max-width: 767px) {
          height: 40px;
        }
      }
      h2 {
        text-transform: capitalize;
        font-size: 30px;
        margin-right: 20px;
      }
      .otherForm {
        div {
          margin: 0 !important;
          padding: 0;
        }
        input::placeholder {
          color: $darkGreyColor;
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
    .acceptInfo {
      font-size: 14px;
    }
    .highlightBox {
      background-image: linear-gradient(
        to bottom,
        $primaryColor calc((0.09 - 1) * 10000%),
        transparent calc(0.09 * 10000%)
      );
      padding: 15px;
      margin-left: -15px;
      border-radius: 5px;
      color: $primaryColor;
      margin-bottom: 20px;
      p {
        margin: 0;
        font-size: 15px;
      }
    }
    .btnLink {
      padding: 15px 20px;
      border: 2px solid $primaryColor;
      border-radius: 2px;
      color: $primaryColor;
      background-color: transparent;
      width: fit-content;
      font-size: 12px;
      font-family: $circularBold;
    }
    .otherContent {
      font-size: 16px;
      ul {
        list-style: none;
        padding-inline-start: 20px;
      }
      li::before {
        content: "\2022";
        font-size: 24px;
        line-height: 16px;
        color: $primaryColor;
        font-weight: 600;
        display: inline-block;
        margin-left: -20px;
        margin-right: 10px;
      }
    }
  }
  .uploadContainer {
    background-image: linear-gradient(
      to bottom,
      var(--primary-color) calc((0.15 - 1) * 10000%),
      transparent calc(0.15 * 10000%)
    );
    display: flex;
    flex-direction: column;
    padding: 45px 30px 30px 30px;
    overflow-y: auto;
    flex-grow: 0;
    flex-basis: 357px;
    .uploadBox {
      background-image: url("../../../assets/logos/dash-bg.svg");
      height: 240px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      @media screen and (max-width: 1366px) {
        height: 200px;
        width: 240px;
        margin: 0 auto;
      }
    }
    .uploadBtn {
      background-color: $primaryColor;
      color: $white;
      border: none;
      border-radius: 4px;
      margin-top: 15px;
      padding: 15px;
      &:disabled {
        background-color: $btnDeactivated;
        cursor: not-allowed;
      }
    }
    .infoText {
      margin: 30px 0 0;
      background-color: $white;
      padding: 30px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      i {
        position: absolute;
        margin: auto;
        font-style: normal;
        font-family: monospace;
        display: flex;
        color: var(--primary-color);
        font-weight: 600;
        font-size: 20px;
        background-color: $white;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        transform: translateY(-104%);
        box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.2);
      }
      p {
        margin: 0;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-basis: unset;
      overflow-y: unset;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 30px;
  cursor: pointer;
  line-height: 18px;
  overflow: hidden;
  padding: 4px;
  @media screen and (max-width: 767px) {
    position: fixed;
    right: 6px;
    top: 6px;
  }
}

.instructionsContent {
  position: relative;
  font-size: 14px;
  * {
    font-size: initial;
    box-sizing: initial;
    &:after,
    &:before {
      font-size: initial;
      box-sizing: initial;
    }
  }
  a {
    text-decoration: underline;
    color: -webkit-link;
  }
}

.deleteModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 90%;
  background-color: $white;
  margin: auto;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0;
}

.deleteModalContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px;
  .closeBtn {
    @media screen and (max-width: 767px) {
      position: absolute;
    }
  }
  h2 {
    text-align: center;
    font-family: $circularStd;
    letter-spacing: 0.76px;
    font-size: 28px;
    span {
      color: $primaryColor;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  .ackSubtitle {
    font-weight: 600;
  }
  .buttonContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    button {
      padding: 18px 30px;
      min-width: 200px;
      font-family: $circularBold;
      color: $ctaColor;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: 0.6px;
      font-size: 15px;
      &.btnPrimary {
        border: 1px solid $ctaColor;
      }
      &.btnLink {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:first-child {
        margin-right: 20px;
      }
      @media screen and (max-width: 767px) {
        min-width: unset;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.helpText {
  display: block;
  padding: 5px 0;
  text-decoration: underline;
  color: $primaryColor;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}

.ackIssueModal {
  max-width: 900px;
  h2 {
    font-family: $circularBold;
  }
  .ackSubtitle {
    margin: 15px 0px 10px;
  }

  .missingReport {
    background-color: rgba($color: $veryLight, $alpha: 0.5);
    max-height: 30vh;
    min-height: 100px;
    overflow-y: auto;

    .distLogo {
      height: 40px;
      width: 125px;
      flex-shrink: 0;
      img {
        width: auto;
        height: 100%;
      }
      p {
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        height: 35px;
        width: 105px;
      }
      @media screen and (max-width: 410px) {
        padding: 0px;
        height: 28px;
        width: 90px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      padding-left: 40px;
      justify-content: center;
      p {
        margin: 0;
        &:first-child {
          font-size: 20px;
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
          @media screen and (max-width: 410px) {
            font-size: 16px;
          }
        }
        &.errorText {
          color: $grey;
          font-size: 12px;
          margin: 0;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        &.otherErrorText {
          color: $grey;
          font-size: 12px;
          margin: 0;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            letter-spacing: -0.7px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
      }
    }
    .otherLogo {
      height: 40px;
      width: 125px;
      background-color: $white;
      color: $black;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      p {
        margin: 0 0 0 5px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        @media screen and (max-width: 410px) {
          font-size: 12px;
          height: 25px;
          width: 90px;
        }
      }

      img {
        height: 20px;
      }

      @media screen and (max-width: 767px) {
        height: 35px;
        width: 105px;
        padding: 0 10px;
      }
    }
    .toolIcon{
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        line-height: normal;
      }
    }
    .headerCell{
      background-color: $light;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: $medium;
      @media screen and (max-width: 767px) {
        padding: 4px;
        font-size: 10px;
      }
      @media screen and (max-width: 410px) {
        padding: 4px;
        font-size: 8px;
      }
    }
    .bodyCell {
      @media screen and (max-width: 410px) {
        padding: 4px;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 10px 5px;
    }
    @media screen and (max-width: 410px) {
      padding: 0px;
    }
  }
  .buttonContainer {
    button {
      background-color: $ctaColor;
      color: $white;
      text-transform: unset;
      @media screen and (max-width: 767px) {
        padding: 10px 15px;
      }
    }
  }
}

.ackTrackIssueModal {
  max-width: 1000px;
  h2 {
    font-family: $circularBold;
  }
  .ackSubtitle {
    margin: 15px 0px 10px;
  }
  .trackTable {
    max-height: 440px;
    background-color: rgba($color: $veryLight, $alpha: 0.5);
    .headerCell {
      background-color: $light;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: $medium;
      &:first-child {
        max-width: 150px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 10px;
      }
      @media screen and (max-width: 767px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .trackDetail {
      display: flex;
      align-items: center;
      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        @media screen and (max-width: 1440px) {
          height: 40px;
          width: 40px;
        }
        @media screen and (max-width: 410px) {
          height: 30px;
          width: 30px;
        }
      }
      svg {
        width: 60px;
        height: auto;
        @media screen and (max-width: 1440px) {
          width: 40px;
        }
        @media screen and (max-width: 410px) {
          width: 30px;
        }
      }
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 0 20px;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          margin: 0 0 0 15px;
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin: 0 0 0 8px;
        }
      }
    }
    .chartData {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 1440px) {
        font-size: 12px;
        font-weight: 600;
      }
    }
    .trackTableCell {
      border-bottom: none;
      &:first-child {
        max-width: 150px;
      }
      @media screen and (max-width: 1440px) {
        padding: 5px 10px;
      }
      @media screen and (max-width: 767px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .issueCell {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .missingError {
        color: $error;
        display: flex;
        align-items: center;
        margin: 0;
        span {
          content: " ";
          background-color: $error;
          height: 20px;
          width: 20px;
          color: $white;
          border-radius: 50%;
          padding-left: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $circularBold;
          margin-left: 8px;
          flex-shrink: 0;
          @media screen and (max-width: 1440px) {
            height: 16px;
            width: 16px;
          }
        }
        @media screen and (max-width: 1440px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 410px) {
        :global {
          .action__menu {
            font-size: 11px;
          }
          .action__value-container {
            justify-content: flex-start;
          }
          .action__placeholder {
            white-space: nowrap;
          }
        }
      }
    }
    input {
      background-color: transparent;
      &:focus::placeholder {
        color: $grey;
      }
    }
  }
  .buttonContainer {
    button {
      background-color: transparent;
      color: $ctaColor;
      border: 1px solid $ctaColor;
      font-size: 14px;
      text-transform: unset;
      &:first-child {
        margin-right: 10px;
        @media screen and (max-width: 767px) {
          margin: 0 0 15px 0;
        }
      }
      &.btnPrimary {
        background-color: $ctaColor;
        color: $white;
      }
      @media screen and (max-width: 1440px) {
        padding: 15px 15px;
      }
      @media screen and (max-width: 767px) {
        padding: 10px 15px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}
