@import "../../../styles/variables.scss";

.loginContent {
  text-align: center;
  width: 100%;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 45px;
  max-width: 1076px;
  @media (max-width: 1025px) {
    max-width: 765px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    max-width: 573px;
    margin-bottom: 40px;
  }
  @media (max-width: 639px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 192px);
    margin-bottom: 0;
  }
}

.formContainer {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 511px;
  padding: 79px 0;
  align-items: center;
  .maintenanceTitle {
    @media (max-width: 639px) {
      font-size: 32px;
    }
  }
  .maintenanceDetail {
    @media (max-width: 639px) {
      text-align: left;
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 29px 0;
  }
  @media screen and (max-width: 479px) {
    padding: 0;
  }
}

.loginContent h1 {
  color: $primaryColor;
  font-weight: 600;
  font-family: $circular;
  font-size: 52px;
  line-height: 69px;
  letter-spacing: -3px;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 48px;
  }
  @media screen and (max-width: 479px) {
    white-space: unset;
    font-size: 40px;
  }
  @media (max-width: 639px) {
    text-align: left;
    padding: 0 20px;
  }
}

.loginContent .subTitle {
  color: $primaryColor;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  @media (max-width: 639px) {
    text-align: left;
    padding: 0 20px;
  }
}

.loginContent .formLink {
  margin: 15px 0;
  display: block;
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
}

.loginContent .formMargin0 > div {
  margin: 0 !important;
}

.loginContent .emailOrPhone {
  margin: 0;
  cursor: pointer;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
}

.loginContent .resendContainer {
  display: flex;
  align-items: center;
  color: $black;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  span {
    margin-right: 10px;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.loginContent .resendContainer button {
  margin-right: 10px;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  background-color: transparent;
  border: none;
  padding: 0;
}

.loginContent .forgotPassword {
  margin: 15px 0;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  @media screen and (max-width: 767px) {
    float: unset;
    display: block;
    margin: 10px 0 0;
  }
}

.loginContent .infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.loginContent .infoIcon {
  position: absolute;
  transform: translate(150%, 160%);
  color: $white;
  right: 0;
  margin-left: 10px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    transform: translate(0, 0);
  }
}

.formgroup label {
  width: 100%;
  padding: 19px 0 14px;
  margin: 0;
  font-weight: 600;
}

.poweredBy {
  img {
    height: 60px;
    width: auto;
  }
  p {
    margin-top: 10px;
  }
}

.registerContent .btnregister {
  padding: 18px;
  font-size: 20px;
  background-color: $white;
  border: 1px solid $dark;
  color: $dark;
  text-transform: uppercase;
  letter-spacing: 0.72px;
}

.registerContent .btnregister:disabled {
  background-color: $white;
  border: 1px solid $grey;
  color: $grey;
}

.registerContent .infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.registerContent .infoIcon {
  position: absolute;
  transform: translate(150%, 160%);
  color: $white;
  right: 0;
  margin-left: 10px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    transform: translate(0, 0);
  }
}

.greyborder {
  border-top: 1px solid $grey;
  margin: 0;
}
a {
  color: $primaryColor;
}

.and {
  font-family: $circularBold;
}

.specialChar {
  font-family: $circularStd;
}
.checkboxAlign {
  text-align: left;
  padding: 15px 20px;
  margin-bottom: -30px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
}
.checkboxAlign a {
  color: $black;
  font-family: $circularBold;
}
.checkboxAlign a:hover {
  color: $black;
  border-bottom: 1px solid $black;
}
.checkboxinput {
  height: 15px;
  width: 15px;
  label {
    vertical-align: middle;
    padding-left: 10px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 90%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}
.socialMediaSectionEnd {
  margin: 20px 20px 16px 20px;
}

.verifyContent {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 45px;
  max-width: 1076px;
  @media (max-width: 1025px) {
    max-width: 765px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    max-width: 573px;
    margin-bottom: 40px;
  }
  @media (max-width: 639px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 192px);
    margin-bottom: 0;
  }
}
