@import "../../../styles/variables.scss";

.pageContent {
  padding: 0;
  text-align: center;
  margin: 0 auto;
  max-width: 692px;
  margin-bottom: 90px;
  margin-top: 40px;
  .header {
    background-color: $appHeaderBackgroundColor;
    padding: 30px;
    color: $appHeaderFontColor;
    h2 {
      font-size: 30px;
      letter-spacing: 1.2px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        display: none;
        &.visible {
          display: block;
        }
      }
    }
    p {
      font-size: 18px;
      letter-spacing: 0.72px;
      font-family: $circularBold;
      margin-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      padding: 20px 10px;
    }
  }
  .title {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: $black;
    color: $white;
    h2 {
      margin: 0;
      font-size: 25px;
      letter-spacing: -1px;
      font-weight: 900;
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    max-width: unset;
    margin-bottom: 0;
  }
}

.preferenceContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 30px;
  table {
    text-align: left;
    margin-bottom: 20px;
    tr {
      border-bottom: 1px solid $light;
    }
    td,
    th {
      padding: 15px 10px;
      &:last-child {
        text-align: center;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    th {
      font-weight: 500;
      text-transform: uppercase;
      color: rgba($color: $black, $alpha: 0.7);
    }
    h4 {
      font-size: 20px;
      margin: 10px 0;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .subText {
      font-size: 15px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    button {
      background-color: $ctaColor;
      border: 1px solid $ctaColor;
      color: $white;
      padding: 15px;
      margin-bottom: 15px;
      min-width: 250px;
      font-family: $circularBold;
      &.primary {
        background-color: transparent;
        color: $ctaColor;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 5px;
    min-height: calc(100vh - 141px);
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
  @media screen and (max-height: 700px) {
    align-items: unset;
  }
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  button {
    padding: 18px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $ctaColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &.primary {
      border: 1px solid $ctaColor;
      &:disabled {
        border: 1px solid $grey;
        color: $grey;
        cursor: not-allowed;
      }
    }
    &.secondary {
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-align: center;
    letter-spacing: 0.76px;
    font-family: $circularBold;
    font-size: 25px;
    .primaryName {
      text-transform: capitalize;
      color: $primaryColor;
      white-space: nowrap;
    }
  }
  p {
    margin: 30px 0;
    text-align: center;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}
