@import "../../styles/variables.scss";

.container a {
  @media screen and (max-width: 767px) {
    color: $white;
  }
  color: $grey;
}
.container a:hover {
  @media screen and (max-width: 767px) {
    color: $accentColor;
  }
  color: $primaryColor;
}

.container {
  display: flex;
  height: 110px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  @media screen and (max-width: 1440px) {
    height: 83px;
  }
  @media screen and (max-width: 1366px) {
    height: 65px;
  }
}
.menuText {
  margin-left: 48px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.76px;
  @media screen and (max-width: 1440px) {
    margin-left: 33px;
    font-size: 13px;
  }
  @media screen and (max-width: 1366px) {
    margin-left: 28px;
    font-size: 12px;
    letter-spacing: 0.76px;
  }
}
.rightNav .menuText {
  padding: 20px 5px;
  border-bottom: 1px solid $light;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.72px;
  line-height: 15px;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
  @media screen and (max-width: 1440px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
    padding: 12px 5px;
  }
}
.leftNav {
  display: flex;
  align-items: center;
  .demoIndicator {
    font-family: $circularBold;
    text-transform: uppercase;
    font-size: 18px;
    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
}
.rightNav {
  display: flex;
  align-items: center;
  text-align: right;
  color: $grey;
  height: 100%;
  :global(.select__single-value) .optional {
    display: none;
  }
  .profileContainer {
    height: 100%;
    display: flex;
    align-items: center;
    top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-left: 48px;
    .dropDown {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      width: 255px;
      right: 0;
      top: 96px;
      padding: 0 15px;
      background-color: $white;
      box-shadow: 0 2px 7px $light;
      z-index: 5;
      max-height: 0;
      overflow-y: hidden;
      transition: padding 0.2s ease-in-out, max-height 0.2s ease-in-out;
      @media screen and (max-width: 1440px) {
        top: 69px;
        width: 210px;
      }
      @media screen and (max-width: 1366px) {
        top: 51px;
      }
    }
    &:hover .dropDown {
      max-height: 230px;
      padding: 15px;
      @media screen and (max-width: 1366px) {
        padding: 10px 15px;
      }
    }
  }
  .highlightedBtn {
    background-color: $accentColor;
    font-size: 18px;
    padding: 12px 25px;
    border-radius: 30px;
    color: $white;
    font-family: $circularBold;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1366px) {
      padding: 6px 15px;
    }
  }
}
.accountImg {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0;
}

.title {
  font-family: $circularBold;
  font-weight: 700;
  font-size: 26px;
  float: left;
}
.bBLogo {
  height: 50px;
  margin-right: 20px;
  top: -1px;
  position: relative;
  @media screen and (max-width: 1440px) {
    height: 42px;
  }
  @media screen and (max-width: 1366px) {
    height: 40px;
  }
}
.menu a {
  margin: 0 20px;
  font-weight: 500;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 20px 40px;
    background-color: $dark;
    border-top: 1px solid $medium;
  }
}
.container .primary {
  color: $primaryColor !important;
}
.accImage {
  width: 35px !important;
  height: auto !important;
  margin-right: 5px;
}
.adminLink {
  margin-right: 8px !important;
}
.imgIcon {
  margin-top: -3px;
  font-size: 15px;
  margin-right: 10px;
  max-width: 29px;
  border-radius: 29px;
}
.headermain {
  background-color: $white;
  border-bottom: 1px solid $darkGreyColor;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.spanLink {
  color: $grey;
  cursor: pointer;
  &:hover {
    color: $primaryColor;
  }
}

.adminSearch {
  display: inline-block;
  width: 350px;
  position: relative;
  height: fit-content;
  margin-top: 10px;
  i {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 14px;
    z-index: 1;
    @media only screen and (max-width: 1366px) {
      top: 9px;
      left: 10px;
    }
  }
  @media only screen and (max-width: 1366px) {
    width: 300px;
    margin-top: 0;
  }
  @media only screen and (max-width: 962px) {
    width: 200px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.adminSearch .selectSearch > div:first-child,
.adminSearch .selectSearch > span + div {
  padding: 5px 15px 5px 35px !important;
  min-height: auto;
  @media screen and (max-width: 1366px) {
    padding: 0 15px 0 30px !important;
  }
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) {
  background: #fff !important;
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) * {
  background: #fff !important;
  text-align: center;
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) > div * {
  text-align: left;
}
.adminSearch .selectSearch > span + div + div > div > div p {
  font-size: 16px;
  text-align: left;
}
.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  .profile {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: -0.79px;
    font-size: 18px;
    margin: 0;
    color: $black;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
  p.optional {
    font-size: 14px !important;
    color: $grey;
    line-height: 13px !important;
  }
  button {
    background-color: $dark !important;
    padding: 8px 20px !important;
    width: auto !important;
    color: $white;
    flex: 0 0 auto;
    border-radius: 2px;
  }
  button:hover {
    color: $grey;
  }
}

.profile {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
  }
  svg {
    height: auto;
    width: auto;
  }
  @media screen and (max-width: 1366px) {
    width: 40px;
    height: 40px;
  }
}

.searchUsers {
  text-align: center;
  pointer-events: none;
}
