@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1500px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  background-color: $white;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    height: calc(100vh - 198px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 150px);
    }
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 132px);
    }
    @media screen and (max-width: 767px) {
      height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 90px);
    padding: 0px 15px;
    max-height: unset;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 0 0 70px 0;
  .pdfContainer {
    max-height: calc(100vh - 540px);
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $light;
    canvas {
      max-width: 100% !important;
      height: auto !important;
      margin: auto;
    }
    :global(.react-pdf__Page__textContent) {
      display: none;
    }
    :global(.react-pdf__Page__annotations) {
      display: none;
    }
    @media screen and (max-width: 1440px) {
      max-height: calc(100vh - 380px);
    }
    @media screen and (max-width: 767px) {
      max-height: calc(100vh - 440px);
    }
  }
  .confirmCheck {
    margin: 10px 0;
    display: flex;
    align-items: center;
    .checkbox {
      color: $primaryColor !important;
      &:hover {
        background-color: transparent !important;
      }
    }
    label {
      margin: 0;
      margin-left: -11px;
    }
    .helpText {
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 17px;
      color: $helpTextColor;
      @media screen and (max-width: 1440px) {
        font-size: 15px;
        line-height: 15px;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 170px;
  }
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 32px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 28px;
    letter-spacing: 1.08px;
  }
  @media screen and (max-width: 1366px) {
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    padding: 30px 0 0;
  }
}

.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $sidebarPrimaryBackgroundColor;
    margin-right: 15px;
  }
  &.infoPrimary {
    background-color: $helpTextColor;
    margin-right: 5px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.tooltip {
  width: 30%;
  font-size: 14px;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.formMainButtons {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 15px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;

  .buttonContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
    & > div {
      margin: 20px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 767px) {
        margin: 8px 0;
      }
    }
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      color: $ctaColor;
      margin: 20px 5px;
      border: 1px solid $ctaColor;
      flex-shrink: 0;
      &.primary {
        background-color: $ctaColor;
        color: $white;
        &:disabled {
          color: $white;
          background-color: $btnDeactivated;
        }
      }
      &:disabled {
        color: $btnDeactivated;
        border: 1px solid $btnDeactivated;
        cursor: not-allowed;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
        flex-shrink: 1;
      }
      @media screen and (max-width: 767px) {
        margin: 5px;
        height: 40px;
        width: 280px;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      margin-top: 3px;
      display: flex;
      align-items: center;
      color: $primaryColor;
      font-family: $circularBold;
    }
    @media screen and (max-width: 767px) {
      width: unset;
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 10px 0;
  }
}

.menuContainer > div {
  max-width: 1500px !important;
}
