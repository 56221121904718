@import "../../../../styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  background-color: $appPrimaryBackgroundColor;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  .subContainer {
    display: flex;
    margin: auto;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
    padding: 10px;
    header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      padding: 30px 20px;
      color: $white;
      p {
        margin-bottom: 0;
        margin-left: 10px;
      }
      svg {
        width: 30px;
        height: auto;
        path {
          fill: $white;
        }
      }
    }
    .details {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      background-color: $white;
      padding: 30px;
      text-align: center;
      color: $black;
      letter-spacing: 0.68px;
      h2 {
        font-family: $circularBold;
        font-weight: normal;
        margin: 20px 0;
        font-size: 36px;
        letter-spacing: -0.69px;
      }
      .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 20px 0;
        img {
          width: 100%;
        }
      }
      .text {
        margin: 0 0 20px;
        span.bold {
          font-family: $circularBold;
        }
        a {
          color: $primaryColor;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .btn {
        margin-top: auto;
        margin-bottom: 0;
        font-size: 20px;
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        color: $ctaColor;
        border: 1px solid $ctaColor;
        font-family: $circularBold;
        padding: 18px;
      }
      .poweredBy {
        margin-top: 30px;
        img {
          height: 60px;
          width: auto;
        }
        p {
          margin-top: 10px;
        }
      }
      .ackInfo {
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 1024px) {
      max-width: fit-content;
    }
    @media screen and (max-width: 767px) {
      max-width: unset;
      padding: 0;
    }
  }
}

.registerContainer {
  padding-left: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    &::before {
      content: " ";
      display: block;
      width: 15%;
      margin: auto;
      border-top: 1px solid $grey;
      flex-grow: 0;
      margin-top: 20px;
    }
    padding-left: 0;
  }
}
