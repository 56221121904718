@import "../../styles/variables.scss";

.container {
  width: 100%;
  flex-shrink: 0;
  z-index: 4;
  background-color: $white;
  border-bottom: 1px solid $darkGreyColor;
  height: 86px;
  .menuContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
    overflow-x: auto;
    .menuItem {
      padding: 20px 0;
      width: 100%;
      min-width: 210px;
      height: 86px;
      position: relative;
      &.active {
        &::after {
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          bottom: 0;
          background-color: $progressAccentColor;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          border-bottom: 1px solid $darkGreyColor;
          & > div {
            border-right: 4px solid $progressAccentColor !important;
            margin: 0 5px;
          }
        }
      }
      & > div {
        padding: 0 20px;
        border-right: 1px solid $darkGreyColor;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 767px) {
          border-right: none;
        }
      }
      .visited {
        color: $progressAccentColor;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        @media screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .notVisited {
        color: $grey;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        @media screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .updated {
        color: $progressUpdateColor;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        @media screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      &:last-child > div {
        border-right: none;
      }
      @media screen and (max-width: 1440px) {
        min-width: 184px;
        padding: 10px 0;
        height: 66px;
      }
      @media screen and (max-width: 1366px) {
        min-width: 184px;
        padding: 11px 0;
      }
      @media screen and (max-width: 1024px) {
        min-width: 162px;
        padding: 10px 0;
        height: 58px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid $darkGreyColor;
        height: unset;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    p {
      margin: 0;
      white-space: nowrap;
      color: $black;
    }
    &.approvedMenu {
      justify-content: flex-start;
      .menuItem > div {
        padding: 9px 20px;
        @media screen and (max-width: 1024px) {
          padding: 8px 20px;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 0px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $black, $alpha: 0.3);
      max-height: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $black, $alpha: 0.5);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 16px;
    height: 66px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
    height: 66px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    height: 58px;
  }
  @media screen and (max-width: 767px) {
    display: none;
    height: unset;
    border-bottom: none;
    text-align: right;
  }
}

.checkIcon {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid $progressAccentColor;
  border-right: 2px solid $progressAccentColor;
  margin: 0 10px;
  @media screen and (max-width: 1024px) {
    margin: 0 6px;
  }
}
