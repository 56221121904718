@import "../../styles/variables.scss";

.container {
  background-color: $sidebarPrimaryBackgroundColor;
  width: 538px;
  min-width: min-content;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 111px);
  z-index: 5;
  position: relative;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  color: $sidebarFontColor1;
  @media screen and (max-width: 1440px) {
    height: calc(100vh - 84px);
    width: 450px;
  }
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 66px);
    width: 400px;
  }
  @media screen and (max-width: 767px) {
    overflow: visible;
    position: relative;
    z-index: 6;
    top: 0;
    height: unset;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: auto;
  }
  .seeMoreLnk {
    display: none;
    background-color: $sidebarPrimaryBackgroundColor;
    padding: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $circularBold;
    }
    span {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: $sidebarPrimaryBackgroundColor;
      position: absolute;
      transform: translateY(50%);
      bottom: 0;
      z-index: -1;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      &::before {
        content: " ";
        width: 115%;
        background: $sidebarPrimaryBackgroundColor;
        position: absolute;
        height: 6px;
        transform: translateY(47%);
        clip-path: polygon(
          100% 0,
          0 0,
          1% 5%,
          4% 15%,
          7% 50%,
          93% 50%,
          96% 15%,
          99% 5%
        );
      }
      &::after {
        content: " ";
        width: 10px;
        height: 10px;
        border-bottom: 2px solid $sidebarFontColor1;
        display: block;
        border-right: 2px solid $sidebarFontColor1;
        transition: 0.5s cubic-bezier(0.64, 0, 0.78, 0);
        transform: translateY(90%) rotate(45deg);
      }
    }
    &.showMore span::after {
      transform: translateY(90%) rotate(-135deg);
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  > p {
    margin-top: auto !important;
    margin-bottom: 85px;
    font-size: 12.5px;
    padding: 20px 20px 0;
    a {
      text-decoration: underline;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $white, $alpha: 0.3);
    max-height: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $white, $alpha: 0.5);
  }
}

.summaryContainer {
  display: none;
  .digitDetails {
    display: none;
  }
  .seeMoreLnk {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $sidebarPrimaryBackgroundColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $sidebarTooltipIconColor;
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 11px;
  }
}

.tooltip {
  width: 30%;
  white-space: normal;
  text-transform: initial;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.menuToolTip {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $sidebarFontColor2;
  text-transform: uppercase;
  flex-shrink: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  p {
    font-family: $circularBold;
  }
  i {
    margin-left: 20px;
  }
  a {
    color: $sidebarFontColor1;
    text-decoration: underline;
    &:hover {
      color: $sidebarFontColor1;
    }
  }
  .validThrough {
    white-space: nowrap;
    font-size: 12px;
    letter-spacing: -0.3px;
    text-transform: initial;
    margin-left: 20px;
    &::before {
      content: "|";
      margin-right: 10px;
      font-size: 18px;
    }
    @media screen and (max-width: 1440px) {
      margin-top: 5px;
      margin-left: 0;
      width: 100%;
      &::before {
        content: unset;
      }
    }
    @media screen and (max-width: 767px) {
      margin-top: 8px;
      position: absolute;
      top: 10px;
      font-size: 11px;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 12px;
    flex-wrap: wrap;
    white-space: unset;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    justify-content: center;
    margin-bottom: 12px;
  }
}
.profile {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  padding: 15px 40px;
  background-color: $sidebarHeaderFooterColor;
  flex-shrink: 0;
  & > div:first-child {
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    @media screen and (max-width: 1440px) {
      height: 48px;
      width: 48px;
    }
    @media screen and (max-width: 1366px) {
      height: 40px;
      width: 40px;
    }
    @media screen and (max-width: 767px) {
      height: 45px;
      width: 45px;
    }
  }
  img {
    width: 100%;
  }
  p {
    font-size: 24px;
    @media screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p:nth-child(3) {
    font-size: 20px !important;
  }
  p:first-child {
    padding: 0 0 0;
    font-family: $circularBold;
    letter-spacing: 0.72px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      line-height: 16px;
    }
  }
  .youraccountCount {
    display: none;
  }
  @media screen and (max-width: 1440px) {
    padding: 14px 30px;
  }
  @media screen and (max-width: 1366px) {
    padding: 15px 30px;
  }
  @media screen and (max-width: 767px) {
    color: $sidebarFontColor1;
    padding: 15px 20px;
    width: 100%;
    min-height: 90px;
    .youraccountCount {
      display: inline-block;
    }
  }
}

.menuContainer {
  font-size: 18px;
  display: flex;
  right: 0;
  top: 21px;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    background: none;
    position: absolute;
  }
  > p {
    margin-top: auto !important;
    font-size: 10px;
    padding-left: 20px;
  }
  .menuItems {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    padding: 28px 28px 15px 40px;
    .active {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
    a {
      padding: 20px 0px 0px 20px;
      font-size: 14px;
      letter-spacing: -0.5px;
      @media screen and (max-width: 767px) {
        margin: 0;
        padding: 18px 40px;
        letter-spacing: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $black;
      }
      .visited {
        color: $success !important;
        i {
          margin-right: 5px;
        }
        .menuIcon {
          font-size: 15px;
          svg {
            color: $success;
            border-radius: 50%;
            background: radial-gradient(
              $white,
              $white,
              $white,
              $white,
              $white,
              transparent,
              transparent,
              transparent,
              transparent
            );
          }
        }
      }
      .notVisited {
        color: $darkYellow !important;
      }
      .updated {
        color: $updateMsg !important;
      }
      box-shadow: 0 3px 10px rgba($color: transparent, $alpha: 0.05);
    }
    @media screen and (max-width: 1440px) {
      padding: 24px 24px 15px 30px;
    }
    @media screen and (max-width: 1366px) {
      padding: 24px 24px 15px 30px;
    }
    @media screen and (max-width: 767px) {
      display: none;
      position: absolute;
      background-color: $white;
      box-shadow: 0 3px 10px rgba($color: $dark, $alpha: 0.4);
      right: 5px;
      top: 5px;
      z-index: 2;
      padding: 0 0 0px;
    }
  }
  .ellipsis {
    margin: -5px -1px 0px 0;
    display: none;
    z-index: 3;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .ellipsisActive {
    color: #2e03e4;
  }

  .active {
    @media screen and (max-width: 767px) {
      color: $primaryColor;
      font-weight: bold;
    }
  }

  .dashboard {
    @media screen and (max-width: 767px) {
      background-color: #2e03e4;
      color: white;
      margin: 0px;
      font-weight: bold;
      padding: 10px 59px 10px 17px;
      display: block;
      img {
        margin: -1px 2px 0;
      }
    }
    display: none;
  }
  .hamburgerToggle {
    position: absolute;
    padding: 3px;
    margin: 4px 0px 0px -4px;
    top: 10px;
    right: 13px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
  }
  .hamburgerToggle:hover {
    background: none;
  }
  .hamburgerToggle img {
    height: 20px;
    width: auto;
  }
}
.menuClose {
  @media screen and (min-width: 768px) {
    max-width: 72px;
    .menuItems a:not(.hamburgerToggle) {
      display: none;
    }
    .menuItems a.hamburgerToggle {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      padding: 0px 15px;
    }
    .menuItems a.hamburgerToggle img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    .profile p {
      display: none;
    }
  }
}

.artistShare {
  font-size: 18px;
  letter-spacing: 0.72px;
  line-height: 30px;

  & > div {
    margin: 20px 0;
  }
  p {
    margin: 0;
    line-height: 28px;
    white-space: normal;
    &.offerTitle {
      color: $sidebarFontColor2;
      font-weight: 700;
    }
    @media screen and (max-width: 1440px) {
      line-height: 20px;
    }
    @media screen and (max-width: 767px) {
      line-height: 22px;
    }
  }
  li {
    position: relative;
    font-size: 10px;
    cursor: text;
    span {
      font-size: 18px;
      position: relative;
      top: 3px;
      left: -4px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.dNone {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.hamburgerIcon {
  margin-bottom: 8px;
}

.digitDetails {
  padding: 28px 28px 15px 40px;

  transition: max-height 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  @media screen and (max-width: 1440px) {
    padding: 24px 24px 15px 30px;
  }
  @media screen and (max-width: 1366px) {
    padding: 24px 24px 15px 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-height: 90px;
    overflow: hidden;
    &.showMore {
      max-height: 1150px;
    }
  }
  .menuItems {
    margin-top: 40px;
    width: 100%;
    text-align: left;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
}

.youraccountCount {
  font-family: $circularBold !important;
  font-size: 48px;
  margin-top: 18px;
  display: inline-block;
  * {
    font-family: $circularBold;
    letter-spacing: 1.92px;
  }
  .odometerContainer {
    overflow: hidden;
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      height: 24px;
    }
  }
  .otherValues {
    font-size: 16px;
    margin: 28px 0 0 -20px;
    font-weight: normal;
    * {
      font-family: $circularBold;
      letter-spacing: 1.2px;
    }
    ul {
      padding: 0 20px;
      margin: 0;
      padding-inline-start: 20px;
      font-size: 17px;
      width: fit-content;
      min-width: 65%;
      background-color: $sidebarHeaderFooterColor;
      li {
        cursor: text;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        &:first-child {
          border: none;
        }
        @media screen and (max-width: 767px) {
          justify-content: center;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    li > div {
      position: relative;
      align-items: center;
      overflow: hidden;
      line-height: normal;
      bottom: -3px;
      font-size: 28px;
      white-space: nowrap;
      margin-right: 20px;
      @media screen and (max-width: 1440px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 1440px) {
      margin: 28px 0 0 -10px;
    }
    @media screen and (max-width: 1366px) {
      margin: 28px 0 0 -10px;
    }
    @media screen and (max-width: 767px) {
      margin: 20px 0;
      width: 100%;
    }
  }
  .countText {
    display: flex;
    margin-top: 10px;
    font-size: 18px;
    font-family: $circularStd;
    white-space: nowrap;
    .royaltyTooltip {
      font-size: 14px;
      font-family: $circularStd;
      letter-spacing: normal;
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
      font-family: $circularBold;
      text-transform: uppercase;
    }
  }
  .textBig {
    font-family: $circularBold;
    font-size: 48px;
    margin: 0 0 0 15px;
    text-transform: capitalize;
    @media screen and (max-width: 1440px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 24px;
    }
  }
  :global {
    .odometer-value {
      overflow: visible;
    }
    .odometer-digit-inner {
      overflow: visible !important;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 24px;
    margin-top: 10px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 1366px) {
    font-size: 24px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

.moreOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transition: 0.4s height;
  overflow: hidden;
  background-color: rgba($color: $white, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  .seeMoreContainer {
    background-color: $sidebarPrimaryBackgroundColor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .profile {
      justify-content: center;
      & > div {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin: 15px 0;
      }
    }
    .youraccountCount {
      padding: 15px 0;
      display: flex;
      font-size: 40px;
      flex-direction: column;
      align-items: center;
      .odometerContainer {
        height: 40px;
      }
      .otherValues {
        display: block;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      .countText {
        display: inline-block;
      }
      .seeMoreLnk {
        display: none;
      }
    }
    .bottomInfoText {
      max-width: 300px;
      text-align: center;
      font-size: 14px;
      a {
        display: inline-block;
        text-decoration: underline;
        color: $sidebarFontColor1;
        margin: 10px 0;
        padding: 5px;
        font-size: 15px;
        &:hover {
          color: $sidebarFontColor1;
        }
      }
    }
    .closeBtn {
      content: " ";
      display: inline-block;
      transform: rotate(-135deg);
      flex-shrink: 0;
      height: 10px;
      width: 10px;
      border-bottom: 3px solid $white;
      border-right: 3px solid $white;
    }
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.closeOverlay {
  height: 0%;
}

.sidebarTextContainer {
  background-color: $sidebarHeaderFooterColor;
  margin: 30px 30px 28px -20px;
  padding: 20px;
  width: 100%;
  overflow: auto;
  position: relative;
  font-size: 14px;
  * {
    box-sizing: initial;
    &:after,
    &:before {
      font-size: initial;
      box-sizing: initial;
    }
  }
  a {
    text-decoration: underline;
    color: -webkit-link;
  }
  @media screen and (max-width: 1440px) {
    margin: 28px 0 0 -10px;
  }
  @media screen and (max-width: 1366px) {
    margin: 28px 0 0 -10px;
  }
  @media screen and (max-width: 767px) {
    margin: 0px;
  }
}
