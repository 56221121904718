@import "../../../../styles/variables.scss";

.loginContent {
  padding: 50px 20px;
  text-align: center;
  margin: 0 auto;
  max-width: 540px;
  margin-bottom: 20px;
}
.loginContent h1 {
  letter-spacing: -0.2px;
  margin-left: 20px;
  font-weight: bold;
  font-family: $circular;
  color: $primaryColor;
  font-size: 58px;
  text-align: left;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 30px;
  }
}
.loginContent h1 span {
  font-size: 48px;
  font-family: $circular;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 24px;
  }
}
.loginContent .forgotPassword {
  width: 100%;
  padding: 15px 0;
  text-align: right;
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: $grey;
}

.next {
  background-color: #b6b6b6 !important;
}

.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  .profile {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: -0.79px;
    font-size: 18px;
    margin: 0;
    color: $black;
  }
  button {
    padding: 8px 20px !important;
    width: auto !important;
    color: $white;
    flex: 0 0 auto;
    border-radius: 2px;
    margin: 0 !important;
  }
}

.noOption {
  text-align: center;
  color: $grey;
}

.loginContent .emailorphone {
  margin: 0;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.formMargin0 div {
  margin-bottom: 0 !important;
}

.spotifySearch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.spotifySearch > * {
  flex-shrink: 0;
}

.spotifySearch > div {
  width: 100%;
}

.spotifySearch > i {
  color: $spotify;
  position: absolute;
  background: radial-gradient(
    $white,
    $white,
    $white,
    $white,
    $white,
    transparent,
    transparent,
    transparent,
    transparent
  );
  border-radius: 50px;
  font-size: 25px;
  transform: translateX(-15px);
}

.emailorphone {
  margin: 0;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  &:hover {
    text-decoration: underline;
    color: $primaryColor;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
