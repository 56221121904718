@import "../../styles/variables.scss";

.dropzone {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  span {
    text-align: center;
    color: $dark;
    margin-bottom: -20px;
    margin-top: 20px;
    &.highlight {
      color: $primaryColor;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

.icon {
  height: auto;
  width: 124px;
  @media screen and (max-width: 1440px) {
    width: 100px;
  }
}

.fileInput {
  display: none;
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.highlight {
  background-color: rgba($color: $primaryColor, $alpha: 0.1);
}

.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: progress;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.5);
  color: $grey;
  i {
    margin-top: 100px;
    margin-left: 4px;
  }
  &.dummy {
    cursor: not-allowed;
  }
}
